import { HistoryLocation } from 'reach__router'
import React, { ReactElement } from 'react'

import PageModuleParser from './PageModuleParser'

export type PageModuleProps = RvG.IReactDefaultProps & {
  pagemodule: RvG.Contentful.PageModuleUnion
  location: HistoryLocation
}

export default function PageModule({
  pagemodule,
  location,
}: PageModuleProps): ReactElement {
  const Parser = PageModuleParser.find((parsermodule) => {
    return pagemodule.__typename === parsermodule.__typename
  })

  if (!Parser) {
    console.log('Module not defined: ', pagemodule)
    return <></>
  }

  return React.createElement(
    Parser.component,
    Parser.props(pagemodule, location)
  )
}
