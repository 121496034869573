import React, { ReactElement } from 'react'
import RvG from '@/types/rvg'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Button from '@objects/button'
import Select from '@objects/formfields/select'

export type TeaserGridFilterProps = RvG.IReactDefaultProps & {
  filterOptions: Array<{ id: string; name: string }>
  onChange(filterArticles: string): void
  currentId: string | null | undefined
}

type FilterOptionsType = {
  label: string
  value: string
}

const useStyles = makeStyles((theme) => ({
  filterRoot: {},
  button: {
    '&$tertiaryButton': {
      fontSize: '16px',
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: '12px',
      paddingBottom: '7px',
      transition: `all 0.3s ${theme.transitions.easing.easeInOut}`,

      '& span': {
        border: 0,
        padding: '0 0 6px',
      },

      '& span:after': {
        display: 'none',
      },

      '&:hover': {
        backgroundColor: theme.palette.button.quaternaryHover,
        borderColor: theme.palette.button.quaternaryHover,
        color: theme.palette.text.invert,
      },
    },
    '&$active': {
      backgroundColor: theme.palette.button.quaternaryHover,
      borderColor: theme.palette.button.quaternaryHover,
      color: theme.palette.text.invert,

      '& span': {
        borderColor: theme.palette.button.quaternaryHover,
      },
    },
  },
  tertiaryButton: {},
  active: {},
  filterTypeButtons: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  filterTypeDropdown: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

export default function TeaserGridFilter({
  ...props
}: TeaserGridFilterProps): ReactElement {
  const { onChange, filterOptions, currentId } = props
  const classes = useStyles()

  const selectOptions: FilterOptionsType[] = filterOptions.map((opt) => {
    return {
      value: opt.id,
      label: opt.name,
    }
  })

  return (
    <div className={classes.filterRoot}>
      <div className={classes.filterTypeButtons}>
        {filterOptions.map((opt) => {
          return (
            <Button
              key={opt.id}
              type={'tertiary'}
              className={clsx([
                classes.button,
                classes.tertiaryButton,
                { [classes.active]: currentId == opt.id },
              ])}
              onClick={() => {
                onChange(opt.id)
              }}
            >
              {opt.name}
            </Button>
          )
        })}
      </div>
      <div className={classes.filterTypeDropdown}>
        <Select
          settings={{
            defaultValue: selectOptions[0],
            onChange: (value, action) => {
              onChange(value?.value ?? '0')
            },
            options: selectOptions,
            settingsTheme: 'dark',
            autoFocus: false,
          }}
        />
      </div>
    </div>
  )
}
