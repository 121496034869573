import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

import ImageCollection from '@components/pressTeaserGrid/pressTeaserGridMediaItem/imageCollection'
import PressYouTubeItem from '@components/pressTeaserGrid/pressTeaserGridMediaItem/pressYouTubeItem'

export type PressTeaserGridMediaItemProps = RvG.IReactDefaultProps & {
  item: RvG.Contentful.MediaListImageListContentfulYouTubeItemUnion
  className?: string
}

const useStyles = makeStyles((theme) => ({
  itemRoot: {},
  itemInner: {},
  itemImage: {},
  itemContent: {
    padding: theme.spacing(2),
  },
  itemHeadline: {
    fontSize: '18px',
    fontFamily: theme.typography.fontFamily2,
  },
  itemCopy: {
    fontSize: '14px',
  },
  imageCollectionWrapper: {},
  imageCollectionItem: {},
}))

function renderItem(
  item: RvG.Contentful.MediaListImageListContentfulYouTubeItemUnion
) {
  switch (item.__typename) {
    case 'ContentfulYouTubeItem':
      return (
        <PressYouTubeItem
          item={item as RvG.Contentful.IContentfulYouTubeItem}
        />
      )
    case 'ContentfulMediaListImageList':
      return (
        <ImageCollection
          {...(item as RvG.Contentful.IContentfulMediaListImageList)}
        />
      )
    default:
      return <div>...</div>
  }
}

export default function PressTeaserGridMediaItem({
  item,
  className,
}: PressTeaserGridMediaItemProps): ReactElement {
  const classes = useStyles()

  return (
    <div className={clsx([className, classes.itemRoot])}>
      {renderItem(item)}
    </div>
  )
}
