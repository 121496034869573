import React, { ReactElement, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'

import useGlobalText from '@hooks/useGlobalText'

import InViewAnimation from '@objects/inViewAnimation'
import Container from '@objects/container'
import Headline from '@objects/headline'
import Select from '@objects/formfields/select'
import Copy from '@components/copy'
import Table, { TableProps } from '@components/table'
import ScheduleOfFines, { ContentTypes } from '@components/fixedmodule'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(15, 0),
    },
  },
  select: {
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(4),
    },
  },
  headline: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('lg')]: {
      marginBottom: theme.spacing(8),
    },
  },
  table: {
    padding: theme.spacing(8, 0),
  },
}))

type DropdownItemTypes = {
  titleNavigation: string
  titleBreadcrumb: string
  fields: {
    fullPath: string
  }
}

export type FineCalculatorProps = RvG.IReactDefaultProps & {
  headline: string
  detailpageUrl: string
  dropdown: DropdownItemTypes[]
  cta: RvG.Contentful.BasicRichTextType
  fixedModules: {
    contentJson: ContentTypes[]
  }
  overspeedTables: TableProps[]
  distanceTables: TableProps[]
}

export default function FineCalculator(
  props: FineCalculatorProps
): ReactElement {
  const intl = useIntl()

  const {
    headline,
    detailpageUrl,
    dropdown,
    cta,
    fixedModules,
    overspeedTables,
    distanceTables,
  } = props
  const classes = useStyles()
  const location = useLocation()
  const { getText } = useGlobalText()

  const filterOptions = [
    {
      label: getText('fineCalculatorOverSpeeding'),
      value: 'overspeeding',
      isLink: false,
    },
    {
      label: getText('fineCalculatorDistanceViolations'),
      value: 'distance',
      isLink: false,
    },
  ]

  const isDetailpage = location.pathname.includes(detailpageUrl)
  const selectedValue = location.hash.replace('#', '') || filterOptions[0].value

  if (dropdown?.length > 0) {
    dropdown.map((item: DropdownItemTypes) => {
      filterOptions.push({
        label: `${item.titleBreadcrumb || item.titleNavigation} →`,
        value: item.fields.fullPath,
        isLink: true,
      })
    })
  }

  function onSelectChange({ value }: { value: string }) {
    const match = filterOptions.filter((f) => f.value === value)[0]
    navigate(match.isLink ? match.value : `#${match.value}`)

    /**
     * Wenn zB die Auswahl "Abstandsverstöße" im Dropdown
     * getroffen wurde, soll der CTA mit dem Hash ergänzt werden
     * damit auf der Detailseite auch "Abstandsverstöße"
     * vorselektiert ist
     */
    if (cta.references[0].fields?.fullPath) {
      let fullPath = cta.references[0].fields.fullPath
      fullPath = fullPath?.split('#')[0]
      cta.references[0].fields.fullPath = `${fullPath}#${match.value}`
    }
  }

  useEffect(() => {
    if (isDetailpage) {
      window.scrollTo({ top: 0 })
    }
  }, [])

  return (
    <section
      className={classes.root}
      role="region"
      aria-label={intl.formatMessage({ id: 'finecalculator.aria.label' })}
    >
      <Container type="nomargin">
        <InViewAnimation
          group={[
            !isDetailpage && (
              <Headline className={classes.headline}>{headline}</Headline>
            ),
            <Select
              key={`fineCalculator-select`}
              className={classes.select}
              settings={{
                defaultValue: filterOptions.filter(
                  (f) => f.value === selectedValue
                ),
                onChange: onSelectChange,
                options: filterOptions,
                settingsTheme: 'dark',
                autoFocus: false,
              }}
            />,
            <ScheduleOfFines
              key={`fineCalculator-ScheduleOfFines`}
              type="bußgeldkatalog"
              content={fixedModules.contentJson}
              active={selectedValue}
              cta={
                !isDetailpage ? (
                  <Copy richtext={cta} type="button" theme="light" />
                ) : null
              }
            />,
          ]}
        />
        {selectedValue === 'overspeeding' &&
          isDetailpage &&
          overspeedTables.map((table: TableProps, i: number) => (
            <InViewAnimation key={`overspeedTables-${i}`}>
              <Table {...table} className={classes.table} />
            </InViewAnimation>
          ))}
        {selectedValue === 'distance' &&
          isDetailpage &&
          distanceTables.map((table: TableProps, i: number) => (
            <InViewAnimation key={`distanceTables-${i}`}>
              <Table {...table} className={classes.table} />
            </InViewAnimation>
          ))}
      </Container>
    </section>
  )
}
