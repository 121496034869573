import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import PageModuleArray from '@tools/PageModuleArray'
import { HistoryLocation } from '@reach/router'

export interface GenericPageProps {
  pagecontext: RvG.PageHelpers.PageContext
  children?: React.ReactChildren
  location: HistoryLocation
  data: {
    contentfulGenericPage: {
      modules: Array<RvG.Contentful.PageModuleUnion>
    }
  }
}

export default function GenericPage({
  data,
  location,
}: GenericPageProps): ReactElement {
  const modules = data.contentfulGenericPage.modules

  return (
    <>
      {!!modules && (
        <PageModuleArray modulearray={modules} location={location} />
      )}
      {/*{presse && (
        <FilterModule
          type={"press"}
          onChange={() => filterOnChange()}
          currentFilter={'2018'}
          filterOptions={[]}

        />
      )}*/}
    </>
  )
}

export const pageQuery = graphql`
  query GenericPageByPath($id: String!, $locale: String!) {
    contentfulGenericPage(id: { eq: $id }, node_locale: { eq: $locale }) {
      modules {
        ...ContentfulModulesFragment
      }
    }
  }
`
