import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'
import useToggleComp from '@hooks/useToggleComp'
import Image from '@objects/image'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  imageCollectionWrapper: {
    '&:hover': {
      '& $imageTeaserImg': {
        transform: 'scale(1.2)',
        '&::after': {
          opacity: 0.6,
        },
      },
      '& $imageTeaserIconGroup': {
        opacity: 1,
      },
    },
  },
  imageTeaserImg: {
    margin: 0,
    transition: 'transform 1s',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: theme.palette.background.greyDark,
      opacity: 0,
      transition: 'opacity 0.5s',
    },
  },
  imageTeaserWrapper: {
    cursor: 'pointer',
    position: 'relative',
  },
  imageTeaserIconGroup: {
    opacity: 0,
    transition: 'opacity 0.5s',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '& *': {
      textAlign: 'center',
      color: 'white',
    },
  },
  itemContent: {
    padding: theme.spacing(4),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  itemTitle: {
    ...theme.typography.h5,
  },
}))

interface ImageCollectionProps {
  images?: Array<RvG.Contentful.IAsset>
  download?: RvG.Contentful.IAsset
  headline?: string
  description?: RvG.Contentful.BasicRichTextType
}

export default function ImageCollection({
  images,
  headline,
  description,
  download,
}: ImageCollectionProps) {
  const classes = useStyles()
  const { toggleOverlay } = useToggleComp()

  return (
    <div
      className={classes.imageCollectionWrapper}
      onClick={() => {
        toggleOverlay(true, {
          type: 'gallery',
          paperProps: { className: 'dark' },
          galleryProps: {
            items: images,
            itemsType: 'image',
            description,
            headline,
            download: download,
          },
          closeButton: 'hide',
        })
      }}
    >
      <div className={classes.imageTeaserWrapper}>
        {images && (
          <Image
            className={classes.imageTeaserImg}
            image={
              {
                ...images[0].fluid,
                aspectRatio: 16 / 9,
              } as RvG.Contentful.IFluidImage
            }
          />
        )}
        <div className={classes.imageTeaserIconGroup}>
          <div>
            <Icon name={'Search'} />
          </div>
          <div>
            <FormattedMessage
              id="media.counter.images"
              values={{ amount: images?.length }}
            />
          </div>
        </div>
      </div>
      <div className={classes.itemContent}>
        {headline && <p className={classes.itemTitle}>{headline}</p>}
      </div>
    </div>
  )
}
