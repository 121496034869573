import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconProps } from '@objects/icon'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import FontSize from '@config/theme/definitions/fontSize'
import { Grid } from '@material-ui/core'
import Button from '@objects/button'
import Select from '@objects/formfields/select'
import Headline from '@objects/headline'
import Container from '@objects/container'
import InViewAnimation from '@objects/inViewAnimation'
import Copy from '@components/copy'
import { useIntl } from 'react-intl'

export interface ILinkGridLink {
  id: string
  icon: IconProps['name']
  label: string
  page: {
    fields: {
      fullPath: string
    }
  }
}

export type LinkGridProps = {
  headline?: string
  copy?: RvG.Contentful.BasicRichTextType
  links?: Array<ILinkGridLink>
}

const useStyles = makeStyles((theme) => ({
  container: {},
  link: {
    width: '100%',
    height: '100%',
    fontSize: FontSize['lg'],
    padding: theme.spacing(3),
    justifyContent: 'flex-start',
  },
  filterTypeButtons: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  filterTypeDropdown: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

export default function LinkGrid({
  headline,
  copy,
  links,
}: LinkGridProps): React.ReactElement {
  const classes = useStyles()
  const location = useLocation()

  const selectOptions = links?.map((link) => ({
    value: link.page.fields?.fullPath,
    label: link.label,
  }))

  function EventHandlerLink(path: string) {
    navigate(location.pathname === path ? '/' : path, {
      replace: true,
      state: {
        preventScroll: true,
      },
    })
  }

  function renderLinks() {
    return links?.map((link) => (
      <Grid key={link.id} item md={6} lg={4} xl={3}>
        <Button
          className={classes.link}
          type={
            location.pathname === link.page?.fields.fullPath
              ? 'main'
              : 'tertiary'
          }
          icon={link.icon}
          iconFontSize={48}
          hideLinkIcon
          onClick={() => EventHandlerLink(link.page.fields?.fullPath)}
          role="menuitem"
        >
          {link.label}
        </Button>
      </Grid>
    ))
  }

  const intl = useIntl()

  return (
    <Container
      className={classes.container}
      type="section"
      aria-label={intl.formatMessage({ id: 'linkgrid.aria.label' })}
    >
      <InViewAnimation
        group={[
          !!headline && <Headline level={2}>{headline}</Headline>,
          !!copy && <Copy richtext={copy} />,
          <div key={`linkgrid-1`} className={classes.filterTypeButtons}>
            <Grid
              container
              wrap="wrap"
              spacing={4}
              role="group"
              aria-label={`${intl.formatMessage({
                id: 'linkgrid.aria.label',
              })} - ${intl.formatMessage({ id: 'options.aria.label' })}`}
            >
              {renderLinks()}
            </Grid>
          </div>,
          <div key={`linkgrid-1`} className={classes.filterTypeDropdown}>
            <Grid container>
              <Grid item xs={12}>
                <Select
                  settings={{
                    value: selectOptions?.find(
                      (o) => o.value === location.pathname
                    ),
                    onChange: (option: Record<string, string>) =>
                      EventHandlerLink(option.value),
                    options: selectOptions,
                    settingsTheme: 'dark',
                    autoFocus: false,
                  }}
                />
              </Grid>
            </Grid>
          </div>,
        ]}
      />
    </Container>
  )
}
