import React, { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@objects/button'
import _ from 'lodash'
import clsx from 'clsx'
import Select from '@objects/formfields/select'
import useGlobalText from '@hooks/useGlobalText'

const useStyles = makeStyles((theme) => ({
  filterRoot: {},
  button: {
    '&$tertiaryButton': {
      fontSize: '16px',
      marginRight: theme.spacing(3),
      marginBottom: theme.spacing(3),
      paddingTop: '12px',
      paddingBottom: '7px',

      '&:hover': {
        '& span': {
          borderColor: theme.palette.button.quaternaryHover,
        },
      },
    },
    '&$active': {
      backgroundColor: theme.palette.button.quaternaryHover,
      borderColor: theme.palette.button.quaternaryHover,
      color: theme.palette.text.invert,

      '& span': {
        borderColor: theme.palette.button.quaternaryHover,
      },
    },
  },
  tertiaryButton: {},
  active: {},
  filterTypeButtons: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginBottom: theme.spacing(11),
      marginTop: theme.spacing(5),
    },
  },
  filterTypeDropdown: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

export type FilterModuleProps = {
  type: string
  filterOptions?: Array<{ label: string; id: string }>
  onChange(filterItems: string): void
  currentFilter: string | null
  radioOpts?: Array<Record<string, unknown>> | []
  radioPosition?: 'top' | 'bottom'
}

export default function FilterModule({
  type,
  currentFilter,
  onChange,
  filterOptions,
}: FilterModuleProps): ReactElement {
  const { getText } = useGlobalText()

  const filterOpts =
    filterOptions && filterOptions?.length > 0
      ? filterOptions
      : [
          { label: '2021', id: '2021' },
          { label: '2020', id: '2020' },
          { label: '2019', id: '2019' },
          { label: '2018', id: '2018' },
        ]

  const classes = useStyles()

  return (
    <div className={classes.filterRoot}>
      <div className={classes.filterTypeButtons}>
        {filterOpts &&
          filterOpts.map((opt) => {
            return (
              <Button
                key={`filterButton-${opt.id}`}
                type={'tertiary'}
                className={clsx([
                  classes.button,
                  classes.tertiaryButton,
                  { [classes.active]: currentFilter == opt.id },
                ])}
                onClick={() => {
                  onChange(opt.id)
                }}
              >
                {opt.label}
              </Button>
            )
          })}
      </div>
      {filterOpts && (
        <div className={classes.filterTypeDropdown}>
          <Select
            settings={{
              defaultValue: filterOpts[0],
              onChange: (value) => {
                onChange(value?.value ?? value?.id ?? '0')
              },
              options: filterOpts,
              settingsTheme: 'dark',
              autoFocus: false,
            }}
          />
        </div>
      )}
    </div>
  )
}
