import React, { ReactElement } from 'react'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles'
import { Link as GatsbyLink } from 'gatsby'
import clsx from 'clsx'

import RvG from '@/types/rvg'
import { GenericPage } from '@/types/genericpage'
import { ArticlePage } from '@/types/articlepage'
import Copy from '@components/copy'
import Icon from '@objects/icon'

export type PressTeaserGridItemProps = RvG.IReactDefaultProps & {
  item: ArticlePage | GenericPage
  className?: string
}

const useStyles = makeStyles((theme) => ({
  itemRoot: {},
  itemInner: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  itemImage: {},
  itemContent: {
    padding: theme.spacing(4),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  itemDate: {
    ...theme.typography.infotext,
    fontSize: '10px',
    lineHeight: 2,
  },
  itemHeadline: {
    fontSize: '18px',
    fontFamily: theme.typography.fontFamily2,
  },
  itemCopy: {
    fontSize: '14px',
  },
  readingTime: {
    marginTop: 'auto',
    fontSize: '10px',
  },
  readingTimeIcon: {
    height: '1rem',
    width: '1rem',
  },
}))

export default function PressTeaserGridItem({
  item,
  className,
}: PressTeaserGridItemProps): ReactElement {
  const classes = useStyles()
  const date = (item?.publishdate as string) || item.updatedAt

  return (
    <div className={clsx([className, classes.itemRoot])}>
      <GatsbyLink to={item.url}>
        <div className={classes.itemInner}>
          {item?.teaserImage?.fluid && (
            <div className={classes.itemImage}>
              <Img
                fluid={{ ...item?.teaserImage?.fluid, aspectRatio: 16 / 9 }}
                alt={''}
              />
            </div>
          )}
          <div className={classes.itemContent}>
            <div className={classes.itemDate}>{date}</div>
            <div className={classes.itemHeadline}>{item.titleNavigation}</div>
            {item?.teaserCopy && (
              <div className={classes.itemCopy}>
                <Copy richtext={item.teaserCopy} />
              </div>
            )}
            {item.readingTime && (
              <div className={classes.readingTime}>
                <Icon className={classes.readingTimeIcon} name={'Duration'} />{' '}
                &nbsp;{item?.readingTime}
              </div>
            )}
          </div>
        </div>
      </GatsbyLink>
    </div>
  )
}
