import React, { Fragment, ReactElement, useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LinearProgress from '@material-ui/core/LinearProgress'
import clsx from 'clsx'

import Button from '@objects/button'
import Container from '@objects/container'
import Headline from '@objects/headline'
import Image from '@objects/image'
import Carousel from '@objects/carousel'
import Copy from '@components/copy'
import { useIntl } from 'react-intl'

import FontSize from '@config/theme/definitions/fontSize'
import { FluidObject } from 'gatsby-image'

const DELAY = 10000 // ms

const useStyles = makeStyles((theme) => ({
  stageRoot: {
    position: 'relative',
    marginBottom: theme.spacing(20),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(15),
      backgroundColor: theme.palette.background.greyDark,
    },
  },
  stageContent: {
    zIndex: 3,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  thumbnail: {
    margin: '0 !important',
  },
  stageImageWrapper: {
    position: 'relative',
  },
  stageImageInner: {
    margin: '0 !important',
    '& .gatsby-image-wrapper': {
      [theme.breakpoints.up('lg')]: {
        height: '488px',
      },
    },
  },
  stageImageGradient: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: `linear-gradient(0deg, ${theme.palette.background.greyDark} 0%, rgba(255,255,255,0) 100%)`,
    opacity: '0.6',
  },
  stageContainer: {
    position: 'relative',
    boxSizing: 'border-box',
    zIndex: 2,
    color: theme.palette.text.invert,
  },
  headline: {
    marginBottom: theme.spacing(4),
  },
  contentBackground: {
    backgroundColor: theme.palette.background.greyDark,
    color: theme.palette.text.invert,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '45%',
      height: '80%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
    },
  },
  contentBackgroundInner: {
    padding: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(4),
      paddingLeft: `calc(100vw / 2 - ${theme.container.lg} / 2)`,
      paddingRight: theme.spacing(10),
      paddingTop: theme.spacing(13),
      paddingBottom: theme.spacing(10),
    },
  },
  contentBackgroundSiding: {
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: '50px',
      position: 'absolute',
      top: 0,
      right: '-25px',
      zIndex: 1,
      backgroundColor: theme.palette.background.greyDark,
      transform: 'skew(-6.5deg)',
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
    },
  },
  cardWrapper: {
    backgroundColor: theme.palette.background.greyDark,
    paddingBottom: theme.spacing(6),

    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      zIndex: 3,
      left: 0,
      right: 0,
      bottom: theme.spacing(12),
      paddingBottom: 0,
      backgroundColor: 'transparent',
    },
  },
  cardContainer: {
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cards: {
    display: 'flex',
    cursor: 'pointer',
    width: 'fit-content',
    [theme.breakpoints.down('lg')]: {
      overflowX: 'auto',
      padding: theme.spacing(4),
    },
    '& .MuiLinearProgress-colorPrimary': {
      backgroundColor: theme.palette.button.disabledText,
    },
  },
  cardLinkWrapper: {
    marginRight: `${theme.spacing(7) + 2}px`,
    border: '2px solid transparent',
    '&:focus': {
      border: '2px solid black',
      outline: '1px dotted white',
      borderRadius: theme.spacing(1),
      transition: 'none',
    },
  },
  card: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.light,
    width: '244px',
    height: theme.spacing(20),
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    flexShrink: 0,
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      '& $cardImage': {
        '& img': {
          transform: 'scale(1.25)',
        },
      },
    },
  },
  cardImage: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    flexShrink: 0,
    '& img': {
      transition: 'transform 1s ease-out, opacity 500ms ease 0s !important',
    },
  },
  cardHeadline: {
    fontSize: FontSize.base,
    lineHeight: 1.2,
    padding: theme.spacing(0, 2),
  },
  cardProgress: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    '@media (prefers-contrast: more)': {
      '&.MuiLinearProgress-root': {
        height: '6px',
      },
      '& .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Determinate':
        {
          boxShadow: `
              inset 0 1px 1px rgba(0, 0, 0, 0.5)
              `,
        },
    },
  },
  lastCard: {
    width: '1px',
    background: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  cta: {
    '& a': {
      '& > span': {
        paddingBottom: theme.spacing(1),
        backgroundImage: `linear-gradient(to bottom, transparent 20%, ${theme.palette.background.light} 21%)`,
        backgroundPosition: '0 100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '0% 2px',
        transition: `background-size 0.3s ${theme.transitions.easing.easeInOut}`,
      },
    },
  },
  playPauseBtn: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 4,
    padding: 0,
    backgroundColor: 'transparent !important',
    [theme.breakpoints.up('lg')]: {
      bottom: theme.spacing(8),
      right: theme.spacing(10),
    },
    '&:focus': {
      border: '2px solid black',
      outline: '1px dotted white',
    },
  },
  headlineAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 0s ${theme.transitions.easing.easeInOut} 0s, transform 0s ${theme.transitions.easing.easeInOut} 0s`,
  },
  copyAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 0s ${theme.transitions.easing.easeInOut} 0s, transform 0s ${theme.transitions.easing.easeInOut} 0s`,
  },
  ctaAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 0s ${theme.transitions.easing.easeInOut} 0s, transform 0s ${theme.transitions.easing.easeInOut} 0s`,
  },
  showElements: {
    '& $copyAnim, & $headlineAnim, & $ctaAnim': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
    '& $headlineAnim': {
      transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0s, transform 1s ${theme.transitions.easing.easeInOut} 0s`,
    },
    '& $copyAnim': {
      transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0.2s, transform 1s ${theme.transitions.easing.easeInOut} 0.2s`,
    },
    '& $ctaAnim': {
      transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0.4s, transform 1s ${theme.transitions.easing.easeInOut} 0.4s`,
    },
  },
}))

export type HighlightStageProps = RvG.IReactDefaultProps & {
  card1Headline: string
  card1HeadlineShort: string
  card1Copy: RvG.Contentful.BasicRichTextType
  card1Image: RvG.Contentful.IAsset
  card1ImageMobile: RvG.Contentful.IAsset
  card1ImageFocalpoint?: RvG.Contentful.imageFocalPoint
  card1Thumbnail: RvG.Contentful.IAsset
  card1Cta: RvG.Contentful.BasicRichTextType
  card2Headline: string
  card2HeadlineShort: string
  card2Copy: RvG.Contentful.BasicRichTextType
  card2Image: RvG.Contentful.IAsset
  card2ImageMobile: RvG.Contentful.IAsset
  card2ImageFocalpoint?: RvG.Contentful.imageFocalPoint
  card2Thumbnail: RvG.Contentful.IAsset
  card2Cta: RvG.Contentful.BasicRichTextType
  card3Headline: string
  card3HeadlineShort: string
  card3Copy: RvG.Contentful.BasicRichTextType
  card3Image: RvG.Contentful.IAsset
  card3ImageMobile: RvG.Contentful.IAsset
  card3ImageFocalpoint?: RvG.Contentful.imageFocalPoint
  card3Thumbnail: RvG.Contentful.IAsset
  card3Cta: RvG.Contentful.BasicRichTextType
}

type ItemCardProps = {
  headline: string
  image: RvG.Contentful.IAsset
  imageMobile: RvG.Contentful.IAsset
  isMobile?: boolean
  isActive?: boolean
  isPaused?: boolean
  onClick: () => void
}

function normalizedCards({
  card1Headline,
  card1HeadlineShort,
  card1Copy,
  card1Image,
  card1ImageMobile,
  card1ImageFocalpoint,
  card1Thumbnail,
  card1Cta,
  card2Headline,
  card2HeadlineShort,
  card2Copy,
  card2Image,
  card2ImageMobile,
  card2ImageFocalpoint,
  card2Thumbnail,
  card2Cta,
  card3Headline,
  card3HeadlineShort,
  card3Copy,
  card3Image,
  card3ImageMobile,
  card3ImageFocalpoint,
  card3Thumbnail,
  card3Cta,
}: HighlightStageProps) {
  return [
    {
      headline: card1Headline,
      headlineShort: card1HeadlineShort,
      copy: card1Copy,
      image: card1Image,
      imageMobile: card1ImageMobile,
      imageFocalPoint: card1ImageFocalpoint,
      thumbnail: card1Thumbnail,
      cta: card1Cta,
    },
    {
      headline: card2Headline,
      headlineShort: card2HeadlineShort,
      copy: card2Copy,
      image: card2Image,
      imageMobile: card2ImageMobile,
      imageFocalPoint: card2ImageFocalpoint,
      thumbnail: card2Thumbnail,
      cta: card2Cta,
    },
    {
      headline: card3Headline,
      headlineShort: card3HeadlineShort,
      copy: card3Copy,
      image: card3Image,
      imageMobile: card3ImageMobile,
      imageFocalPoint: card3ImageFocalpoint,
      thumbnail: card3Thumbnail,
      cta: card3Cta,
    },
  ]
}

function ItemCard({
  headline,
  image,
  imageMobile,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isMobile,
  onClick,
  isActive,
  isPaused,
}: ItemCardProps): ReactElement {
  const classes = useStyles()
  const [progress, setProgess] = useState(0)

  useEffect(() => {
    if (isActive) {
      if (!isPaused) {
        const interval = setInterval(
          () => setProgess(progress < 100 ? progress + 1 : 100),
          DELAY / 100
        )
        return () => clearInterval(interval)
      }
    } else {
      setProgess(0)
    }
  }, [isActive, progress, isPaused])

  useEffect(() => {
    if (!isPaused) {
      setProgess(0)
    }
  }, [isPaused])

  return (
    <div className={classes.card} onClick={onClick}>
      <div className={classes.cardImage}>
        <Image
          className={classes.thumbnail}
          image={image.fluid}
          imageMobile={imageMobile?.fluid}
          imgStyle={{ margin: '0' }}
        />
      </div>
      <div className={classes.cardHeadline}>{headline}</div>
      {isActive && (
        <LinearProgress
          variant="determinate"
          value={progress}
          className={classes.cardProgress}
        />
      )}
    </div>
  )
}

export default function HighlightStage(
  props: HighlightStageProps
): ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const [swiper, setSwiper] = useState({})
  const [activeIndex, setActiveIndex] = useState(1)
  const [isPaused, setPaused] = useState(false)
  const cards = normalizedCards(props)

  function onSlideChange({ activeIndex }: { activeIndex: number }) {
    // Seems like a swiper bug!
    // first slide in second round, activeIndex is 4
    if (activeIndex > cards.length) {
      setActiveIndex(1)
    } else {
      setActiveIndex(activeIndex)
    }
  }

  useEffect(() => {
    if (typeof swiper.allowTouchMove === 'boolean') {
      // not working as component property
      swiper.allowTouchMove = !isLarge
    }
  }, [isLarge])

  useEffect(() => {
    if (!swiper.autoplay) return

    // https://github.com/nolimits4web/swiper/issues/4047
    if (isPaused) {
      swiper.autoplay.stop()
    } else {
      swiper.autoplay.start()
    }
  }, [isPaused])

  function PlayPauseButton() {
    const intl = useIntl()

    return (
      <Button
        id="playPauseButton"
        type="icon"
        icon={isPaused ? 'StagePlay' : 'StagePause'}
        className={classes.playPauseBtn}
        onClick={() => setPaused(!isPaused)}
        aria-label={intl.formatMessage({
          id: isPaused
            ? 'carousel.label.pauseButton'
            : 'carousel.label.playButton',
        })}
      />
    )
  }

  function Content({
    headline,
    copy,
    cta,
  }: {
    headline: string
    copy: RvG.Contentful.BasicRichTextType
    cta: RvG.Contentful.BasicRichTextType
  }) {
    const [show, setShow] = useState(false)
    useEffect(() => {
      setTimeout(() => setShow(true), 1)
    }, [headline])

    return (
      <div
        className={clsx(classes.contentBackgroundInner, {
          [classes.showElements]: show,
        })}
      >
        <Headline
          className={clsx(classes.headline, classes.headlineAnim)}
          level={1}
        >
          {headline}
        </Headline>
        <Copy className={classes.copyAnim} richtext={copy} />
        <Copy richtext={cta} className={clsx(classes.cta, classes.ctaAnim)} />
      </div>
    )
  }
  const intl = useIntl()

  return (
    <section
      className={classes.stageRoot}
      tabIndex={0}
      role="region"
      aria-label={intl.formatMessage({ id: 'highlight.stage.aria.label' })}
    >
      {isLarge && <PlayPauseButton />}
      <Carousel
        onSwiper={setSwiper}
        onSlideChange={onSlideChange}
        speed={800}
        autoplay={{
          delay: DELAY,
          disableOnInteraction: false,
        }}
        loop
        aria-hidden
      >
        {cards?.map((card, i) => {
          const cardImage: FluidObject = { ...card.image.fluid }

          const cardImageMobile: FluidObject = { ...card.imageMobile?.fluid }

          if (!isLarge) {
            cardImage.aspectRatio = 1.333
          }

          return (
            <Fragment key={`highlightcardslide-${i}`}>
              <div className={classes.stageImageWrapper}>
                <Image
                  className={classes.stageImageInner}
                  image={cardImage}
                  imageMobile={cardImageMobile}
                  focalPoint={card.imageFocalPoint || 'Upper right'}
                  imgStyle={{ margin: '0' }}
                  lazyLoadingOff={true}
                />
                {!isLarge && <PlayPauseButton />}
              </div>
              {isLarge && <div className={classes.stageImageGradient} />}
            </Fragment>
          )
        })}
      </Carousel>
      <div
        className={classes.stageContent}
        aria-label={intl.formatMessage({
          id: 'highlight.stage.content.aria.label',
        })}
      >
        <div className={classes.contentBackground}>
          <Content
            headline={
              cards[activeIndex - 1 === -1 ? 0 : activeIndex - 1].headline
            }
            copy={cards[activeIndex - 1 === -1 ? 0 : activeIndex - 1].copy}
            cta={cards[activeIndex - 1 === -1 ? 0 : activeIndex - 1].cta}
          />
          <div className={classes.contentBackgroundSiding} />
        </div>
        <div className={classes.cardWrapper}>
          <Container className={classes.cardContainer} type="nomargin">
            <div className={classes.cards} role="menu">
              {cards.map((card, i) => {
                const handleOnKeyDown = (e: { key: string }) => {
                  const activeElement = document.getElementById(
                    `highlightcard-${activeIndex - 1}`
                  )
                  const playPauseButton =
                    document.getElementById('playPauseButton')
                  switch (e.key) {
                    case 'ArrowRight':
                      if (activeIndex === 3) return playPauseButton?.focus()
                      if (
                        !!activeElement &&
                        !!activeElement.nextElementSibling
                      ) {
                        activeElement.nextElementSibling.focus()
                      }
                      return swiper.slideTo(activeIndex + 1)
                    case 'ArrowLeft':
                      if (activeIndex === 1) return swiper.slideTo(1)
                      if (
                        !!activeElement &&
                        !!activeElement.previousElementSibling
                      ) {
                        activeElement.previousElementSibling.focus()
                      }
                      return swiper.slideTo(activeIndex - 1)
                    default:
                  }
                }

                return (
                  <div
                    className={classes.cardLinkWrapper}
                    tabIndex={0}
                    onKeyDown={(e) => handleOnKeyDown(e)}
                    key={`highlightcard-${i}`}
                    id={`highlightcard-${i}`}
                    role="menuitem"
                  >
                    <ItemCard
                      key={`highlightcard-${i}`}
                      headline={card.headlineShort}
                      image={card.thumbnail}
                      onClick={() => swiper.slideTo(i + 1)}
                      isActive={activeIndex === i + 1}
                      isPaused={isPaused}
                    />
                  </div>
                )
              })}
            </div>
          </Container>
        </div>
      </div>
    </section>
  )
}
