import React, { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'

import InViewAnimation from '@objects/inViewAnimation'
import { Grid } from '@material-ui/core'
import Headline from '@objects/headline'
import Container from '@objects/container'
import Teaser from '@objects/teaser'
import Button from '@objects/button'

const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(0deg, ${theme.palette.background.light} 78%, ${theme.palette.background.default} 78%)`,
    color: theme.palette.text.primary,
    padding: theme.spacing(0, 0, 10, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0, 20, 0),
    },
    '& .swiper-slide': {
      display: 'flex',
      alignItems: 'stretch',
      height: 'auto',
    },
  },
  headline: {
    marginBottom: '24px',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '40px',
    },
  },
  teaser: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  buttonWrapper: {
    marginTop: '40px',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
    },
    '& a:focus': {
      border: '2px dotted currentColor',
    },
  },
}))

export type NewestArticlePages = {
  teaserCopy: RvG.Contentful.BasicRichTextType
  teaserImage?: RvG.Contentful.IAsset
  teaserTitle: string
  fields: {
    fullPath: string
  }
  readingTime: string
}

export type NewestArticleTeaserRowProps = RvG.IReactDefaultProps & {
  headline: string
  fullPath: string
  newestArticlePages?: NewestArticlePages[]
}

export default function NewestArticleTeaserRow({
  headline,
  fullPath,
  newestArticlePages,
}: NewestArticleTeaserRowProps): ReactElement {
  const classes = useStyles()
  const intl = useIntl()

  function renderItems() {
    return newestArticlePages?.map((content: NewestArticlePages, i: number) => (
      <Grid
        key={`default-teaser-${i}`}
        item
        xs={12}
        md={6}
        lg={3}
        role="menuitem"
      >
        <InViewAnimation
          style={{ transitionDelay: `${100 * (i % 4)}ms`, height: '100%' }}
        >
          <Teaser
            className={classes.teaser}
            headline={content.teaserTitle}
            copy={content.teaserCopy}
            image={content.teaserImage}
            fullPath={content.fields?.fullPath}
            readingTime={content.readingTime}
            showReadingTime={true}
          />
        </InViewAnimation>
      </Grid>
    ))
  }
  return (
    <section
      className={classes.root}
      aria-label={intl.formatMessage({ id: 'newest.articles.aria.label' })}
      role="region"
    >
      <Container type="nomargin">
        <InViewAnimation>
          <Headline className={classes.headline}>{headline}</Headline>
        </InViewAnimation>
        <Grid
          container
          wrap="wrap"
          spacing={4}
          alignItems="stretch"
          role="group"
          aria-label={intl.formatMessage({
            id: 'newest.articles.teasers.aria.label',
          })}
        >
          {renderItems()}
        </Grid>
        {fullPath && (
          <InViewAnimation>
            <div className={classes.buttonWrapper} role="button">
              <Button to={fullPath} type={'main'} hideLinkIcon={true}>
                {intl.formatMessage({ id: 'button.showall' })}
              </Button>
            </div>
          </InViewAnimation>
        )}
      </Container>
    </section>
  )
}
