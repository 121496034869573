import React from 'react'

import useToggleComp from '@hooks/useToggleComp'
import YouTube from '@objects/youtube'
import { makeStyles } from '@material-ui/core/styles'

interface PressYouTubeItemProps {
  item: RvG.Contentful.IContentfulYouTubeItem
}

const useStyles = makeStyles((theme) => ({
  itemContent: {
    padding: theme.spacing(4),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  itemTitle: {
    ...theme.typography.h5,
  },
}))

export default function PressYouTubeItem({ item }: PressYouTubeItemProps) {
  const { toggleOverlay } = useToggleComp()
  const classes = useStyles()

  return (
    <div>
      <YouTube
        youTubeId={item.youTubeId}
        thumbnail={item.thumbnail}
        thumbnailOnly
        onThumbnailClick={() => {
          toggleOverlay(true, {
            type: 'gallery',
            paperProps: { className: 'dark' },
            galleryProps: {
              items: [item],
              itemsType: 'youtube',
              headline: item.title,
              description: item.description,
              closeButton: 'hide',
            },
          })
        }}
      />
      <div className={classes.itemContent}>
        {item.title && <p className={classes.itemTitle}>{item.title}</p>}
      </div>
    </div>
  )
}
