import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useToggleComp from '@hooks/useToggleComp'
import Img, { FluidObject } from 'gatsby-image'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import Icon from '@objects/icon'
import { FormattedMessage } from 'react-intl'

export type MaterialShopItemProps =
  RvG.Contentful.IContentfulModuleMaterialShopItem & {
    className?: string
  }

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    background: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    cursor: 'pointer',

    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 24px)',
    },
  },
  imgWrapper: {
    backgroundColor: theme.palette.background.greyDark,
    transition: 'padding 0.2s linear',

    [theme.breakpoints.up('md')]: {
      maxHeight: '244px',
      height: '244px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      '& img': {
        objectFit: 'contain !important',
        maxHeight: '244px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      // padding: theme.spacing(8, 0),
    },

    '$showActions &': {
      paddingBottom: 0,
    },
  },
  itemContentWrapper: {
    padding: theme.spacing(4),
    // overflow: 'hidden',
    position: 'relative',
    background: 'white',
    top: 0,
    transition: 'all 0.2s linear',
    [theme.breakpoints.up('lg')]: {
      '$showActions &': {
        transform: `translateY(-20px)`, // theme.spacing(-15.5),
        marginBottom: '-20px',
      },
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  showActions: {},
  itemHeadline: {
    lineHeight: '22px',
  },
  itemActionsBar: {
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.2s linear',
    maxHeight: 0,
    overflow: 'hidden',
    height: 'auto',
    '$showActions &': {
      maxHeight: '20px', // theme.spacing(16),
      [theme.breakpoints.up('lg')]: {
        // marginBottom: theme.spacing(-16),
      },
    },
  },
  itemActionsBarInner: {
    display: 'flex',
    marginTop: 'auto',
    flexDirection: 'row',
  },
  downloadAction: {
    marginRight: theme.spacing(2),
    '& svg': {
      transform: 'scale(1.08) translateY(-2px)',
    },
  },
  basketAction: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    cursor: 'pointer',
  },
}))

export default function MaterialShopItem({
  className,
  ...item
}: MaterialShopItemProps): React.ReactElement {
  const { id, title, images } = item
  const classes = useStyles()
  const { toggleOverlay } = useToggleComp()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [showActions, setShowActions] = useState<boolean>(false)

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.showActions]: !isDesktop || showActions,
      })}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
      onClick={() => {
        toggleOverlay(true, {
          type: 'materialShop',
          paperProps: { className: 'dark' },
          materialShopProps: {
            id,
            item,
          },
          closeButton: 'hide',
        })
      }}
    >
      <div className={classes.imgWrapper}>
        {isDesktop && (
          <Img fluid={{ ...images?.[0]?.fluid } as FluidObject} alt={''} />
        )}
        {!isDesktop && (
          <Img fluid={{ ...images?.[0]?.fluid } as FluidObject} alt={''} />
        )}
      </div>
      <div className={classes.itemContentWrapper}>
        <div className={classes.itemHeadline}> {title}</div>
        <div className={clsx(classes.itemActionsBar, {})}>
          <div className={clsx(classes.itemActionsBarInner, {})}>
            <div
              className={classes.basketAction}
              onClick={() => {
                toggleOverlay(true, {
                  type: 'materialShop',
                  paperProps: { className: 'dark' },
                  materialShopProps: {
                    id,
                    item,
                  },
                  closeButton: 'hide',
                })
              }}
            >
              <span>
                <FormattedMessage id="materialshop.moreInfos" />
              </span>
              <Icon viewBox={'0 0 36 36'} name="Add" color="primary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
