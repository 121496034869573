import { HistoryLocation } from 'reach__router'

import useDateFns from '@hooks/useDateFns'

import Stage, { StageProps } from '@components/stage'
import BigTeaser, { BigTeaserProps } from '@components/bigTeaser'
import HighlightStage, { HighlightStageProps } from '@components/highlightStage'
import YouTubeGallery, { YouTubeGalleryProps } from '@components/youTubeGallery'
import TeaserRow, { TeaserRowProps } from '@components/teaserRow'
import NewestArticleTeaserRow, {
  NewestArticleTeaserRowProps,
} from '@components/newestArticleTeaserRow'
import ArticleTeaserGrid, {
  ArticleTeaserGridProps,
} from '@components/articleTeaserGrid'
import InfoBox, { InfoBoxProps } from '@components/infoBox'
import TeaserGroup, { TeaserGroupProps } from '@components/teaserGroup'
import Table, { TableProps } from '@components/table'
import PressTeaserGrid, {
  PressTeaserGridProps,
} from '@components/pressTeaserGrid'
import QuizKnowledge, { IQuizKnowledgeProps } from '@components/quizKnowledge'

import FixedModule, { FixedModuleProps } from '@components/fixedmodule'
import FineCalculator, { FineCalculatorProps } from '@components/fineCalculator'
import DownloadShop, { DownloadShopProps } from '@components/downloadShop'
import DownloadShopTeaserList, {
  DownloadShopTeaserListProps,
} from '@components/downloadShopTeaserList'
import { ArticlePage } from '@/types/articlepage'
import LinkGrid, { LinkGridProps } from '@components/linkGrid'
import MaterialShop, { MaterialShopProps } from '@components/materialShop'
import TextModule, { TextModuleProps } from '@components/text'
import { useIntl } from 'react-intl'
import YouTube, { YouTubeProps } from '@objects/youtube'

type ModulePropsUnion = RvG.IReactDefaultProps | StageProps

type PageModuleParserType = {
  __typename: string
  component: React.ElementType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: (mod: any, location: HistoryLocation) => ModulePropsUnion
}

const PageModuleParser: Array<PageModuleParserType> = [
  {
    __typename: 'ContentfulModuleStage',
    component: Stage,
    props: (mod: RvG.Contentful.IContentfulModuleStage): StageProps => {
      return {
        headline: mod.headline,
        image: mod.image,
        imageMobile: mod.imageMobile,
        imageFocalPoint: mod.imageFocalPoint,
        type: mod.type,
        copy: mod.copy,
      }
    },
  },
  {
    __typename: 'ContentfulModuleBigTeaser',
    component: BigTeaser,
    props: (mod: RvG.Contentful.IContentfulModuleBigTeaser): BigTeaserProps => {
      return {
        type: mod.type,
        theme: mod.theme,
        layout: mod.layout,
        headline: mod.headline,
        imageFocalPoint: mod.imageFocalPoint,
        image: mod.image,
        mobileImage: mod.mobileImage,
        cta: mod.cta,
        copy: mod.copy,
      }
    },
  },
  {
    __typename: 'ContentfulModuleBox',
    component: InfoBox,
    props: (component: RvG.Contentful.IBoxRef): InfoBoxProps => {
      return {
        type: component.type,
        image: component.image?.image?.fluid,
        imageAlt: component.image?.image?.description,
        headline: component.headline,
        copy: component.copy,
        relatedLinks: component.relatedLinks,
        hasMaxWidth: component.hasMaxWidth,
      }
    },
  },
  {
    __typename: 'ContentfulModuleHighlightStage',
    component: HighlightStage,
    props: (
      mod: RvG.Contentful.IContentfulModuleHighlightStage
    ): HighlightStageProps => {
      return {
        card1Headline: mod.card1Headline,
        card1HeadlineShort: mod.card1HeadlineShort,
        card1Image: mod.card1Image,
        card1ImageMobile: mod.card1ImageMobile,
        card1ImageFocalpoint: mod.card1ImageFocalpoint,
        card1Thumbnail: mod.card1Thumbnail,
        card1Copy: mod.card1Copy,
        card1Cta: mod.card1Cta,
        card2Headline: mod.card2Headline,
        card2HeadlineShort: mod.card2HeadlineShort,
        card2Image: mod.card2Image,
        card2ImageMobile: mod.card2ImageMobile,
        card2ImageFocalpoint: mod.card2ImageFocalpoint,
        card2Thumbnail: mod.card2Thumbnail,
        card2Copy: mod.card2Copy,
        card2Cta: mod.card2Cta,
        card3Headline: mod.card3Headline,
        card3HeadlineShort: mod.card3HeadlineShort,
        card3Image: mod.card3Image,
        card3ImageMobile: mod.card3ImageMobile,
        card3ImageFocalpoint: mod.card3ImageFocalpoint,
        card3Thumbnail: mod.card3Thumbnail,
        card3Copy: mod.card3Copy,
        card3Cta: mod.card3Cta,
      }
    },
  },
  {
    __typename: 'ContentfulModuleYouTubeGallery',
    component: YouTubeGallery,
    props: (
      mod: RvG.Contentful.IContentfulModuleYouTubeGallery
    ): YouTubeGalleryProps => {
      return {
        headline: mod.headline,
        copy: mod.copy,
        items: mod.items,
      }
    },
  },
  {
    __typename: 'ContentfulYouTubeItem',
    component: YouTube,
    props: (mod: RvG.Contentful.IContentfulYouTubeItem): YouTubeProps => {
      return {
        youTubeId: mod.youTubeId,
        thumbnail: mod.thumbnail,
        description: mod.description,
        isGalleryItem: false,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserRow',
    component: TeaserRow,
    props: (mod: RvG.Contentful.IContentfulModuleTeaserRow): TeaserRowProps => {
      return {
        headline: mod.headline,
        type: mod.type,
        relatedContent: mod.relatedContent,
        theme: mod.theme,
      }
    },
  },
  {
    __typename: 'ContentfulModuleLinkGrid',
    component: LinkGrid,
    props: (mod: RvG.Contentful.IContentfulModuleLinkGrid): LinkGridProps => {
      return {
        headline: mod.headline,
        copy: mod.copy,
        links: mod.links,
      }
    },
  },
  {
    __typename: 'ContentfulModuleNewestArticle',
    component: NewestArticleTeaserRow,
    props: (
      mod: RvG.Contentful.IContentfulModuleNewestArticle
    ): NewestArticleTeaserRowProps => {
      const { formatUnixTime } = useDateFns()
      const sortedSubPages = (mod.page.subPages as Array<ArticlePage>)
        ?.filter((page) =>
          mod.category
            ? page.category?.find((cat) => cat.name === mod.category?.name)
            : true
        )
        ?.sort((pageA, pageB) => {
          const timeA = formatUnixTime(
            pageA.publishdate || pageA.updatedAt,
            'dd.MM.y'
          )
          const timeB = formatUnixTime(
            pageB.publishdate || pageB.updatedAt,
            'dd.MM.y'
          )
          if (timeA < timeB) {
            return 1
          }
          if (timeA > timeB) {
            return -1
          }
          return 0
        })

      return {
        headline: mod.headline,
        fullPath: mod.page.fields.fullPath,
        newestArticlePages: sortedSubPages
          ?.slice(0, mod.teaserAmount ? parseInt(mod.teaserAmount) : 4)
          .map((page) => {
            const returnObj = {
              teaserTitle: page.teaserTitle,
              teaserCopy: page.teaserCopy,
              teaserImage: page.teaserImage,
              fields: {
                fullPath: page.fields.fullPath,
              },
              readingTime: '',
            }

            if (page.readingTime) {
              returnObj.readingTime = (page as ArticlePage).readingTime
            }
            return returnObj
          }),
      }
    },
  },
  {
    __typename: 'ContentfulModuleArticleTeaserGrid',
    component: ArticleTeaserGrid,
    props: (
      mod: RvG.Contentful.IContentfulModuleArticleTeaserGrid
    ): ArticleTeaserGridProps => {
      const { formatUnixTime } = useDateFns()
      let articles: Array<ArticlePage> = []
      if (mod.generic_page) {
        articles = articles.concat(
          ...mod.generic_page
            .filter((page) => page.subPages !== null)
            .map((page) =>
              (page.subPages as Array<ArticlePage>)
                .filter((article) => article.id)
                .map((article) => article)
                .sort((pageA, pageB) => {
                  const timeA = formatUnixTime(
                    !!mod.sortByCustomUpdatedDate &&
                      pageA.customUpdatedDate !== null
                      ? pageA.customUpdatedDate
                      : pageA.publishdate || pageA.updatedAt,
                    'dd.MM.y'
                  )
                  const timeB = formatUnixTime(
                    !!mod.sortByCustomUpdatedDate &&
                      pageB.customUpdatedDate !== null
                      ? pageB.customUpdatedDate
                      : pageB.publishdate || pageB.updatedAt,
                    'dd.MM.y'
                  )

                  if (timeA < timeB) {
                    return 1
                  }
                  if (timeA > timeB) {
                    return -1
                  }
                  return 0
                })
            )
        )
      }

      return {
        headline: mod.headline,
        titleInternal: mod.titleInternal,
        copy: mod.copy,
        showYearFilter: mod.showYearFilter,
        showCategoryFilter: mod.showCategoryFilter,
        articles: articles,
        sortByCustomUpdatedDate: mod.sortByCustomUpdatedDate,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTeaserGroup',
    component: TeaserGroup,
    props: (
      mod: RvG.Contentful.IContentfulModuleTeaserGroup
    ): TeaserGroupProps => {
      return {
        headline: mod.headline,
        type: mod.type,
        theme: mod.theme,
        teasers: mod.teasers,
        cta: mod.cta,
      }
    },
  },
  {
    __typename: 'ContentfulModuleTable',
    component: Table,
    props: (mod: RvG.Contentful.IContentfulModuleTable): TableProps => {
      return {
        hasContainer: true,
        headline: mod.headline,
        copy: mod.copy,
        table: mod.table,
        tableCellAlignment: mod.tableCellAlignment,
        legend: mod.legend,
      }
    },
  },
  {
    __typename: 'ContentfulModuleText',
    component: TextModule,
    props: (mod: RvG.Contentful.IContentfulModuleText): TextModuleProps => {
      return {
        copy: mod.copy,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFixed',
    component: FixedModule,
    props: (mod: RvG.Contentful.IContentfulModuleFixed): FixedModuleProps => {
      return {
        type: mod.type,
        content: mod.contentJson,
      }
    },
  },
  {
    __typename: 'ContentfulModuleFineCalculator',
    component: FineCalculator,
    props: (
      mod: RvG.Contentful.IContentfulModuleFineCalculator
    ): FineCalculatorProps => {
      return {
        headline: mod.headline,
        detailpageUrl: mod.detailpageUrl,
        dropdown: mod.dropdown,
        cta: mod.cta,
        fixedModules: mod.fixedModules,
        overspeedTables: mod.overspeedTables,
        distanceTables: mod.distanceTables,
      }
    },
  },
  {
    __typename: 'ContentfulModulePressMediaTeaserGrid',
    component: PressTeaserGrid,
    props: (
      mod: RvG.Contentful.IContentfulModulePressMediaTeaserGrid
    ): PressTeaserGridProps => {
      return {
        headline: mod.headline,
        copy: mod.copy,
        mediaLists: mod.mediaLists,
        pressArticles: mod.generic_page?.length
          ? mod.generic_page[0].subPages
          : [],
      }
    },
  },
  {
    __typename: 'ContentfulModuleQuizKnowledge',
    component: QuizKnowledge,
    props: (
      mod: RvG.Contentful.IContentfulModuleQuizKnowledge
    ): IQuizKnowledgeProps => {
      return {
        image: mod.image,
        imageFocalPoint: mod.imageFocalPoint,
        headline: mod.headline,
        copy: mod.copy,
        endscreenImage: mod.endscreenImage,
        endscreenSuccessNumber: mod.endscreenSuccessNumber,
        endscreenCopySuccess: mod.endscreenCopySuccess,
        endscreenCopyFailed: mod.endscreenCopyFailed,
        questions: mod.questions,
      }
    },
  },
  {
    __typename: 'ContentfulModuleDownloadShop',
    component: DownloadShop,
    props: (
      mod: RvG.Contentful.IContentfulModuleDownloadShop
    ): DownloadShopProps => {
      return {
        titleInternal: mod.titleInternal,
        categories: mod.categories,
      }
    },
  },
  {
    __typename: 'ContentfulModuleDownloadShopTeaserList',
    component: DownloadShopTeaserList,
    props: (
      mod: RvG.Contentful.IContentfulModuleDownloadShopTeaserList
    ): DownloadShopTeaserListProps => {
      return {
        isArticle: false,
        headline: mod.headline,
        sectionId: mod.sectionId,
        downloadShopItems: mod.downloadShopItems,
      }
    },
  },
  {
    __typename: 'ContentfulModuleMaterialShop',
    component: MaterialShop,
    props: (
      mod: RvG.Contentful.IContentfulModuleMaterialShop
    ): MaterialShopProps => {
      const intl = useIntl()
      return {
        titleInternal: mod.titleInternal,
        copy: mod.copy,
        contact: mod.contact,
        items: mod.categories.reduce((acc, curr) => {
          return [
            ...acc,
            ...curr.entries.map((item) => ({
              ...item,
              category: curr,
              title: intl.formatMessage(
                { id: `materialshop.category.${curr.identifier}.label` },
                { value: item.name }
              ),
            })),
          ]
        }, [] as Array<RvG.Contentful.IContentfulModuleMaterialShopItem>),
      }
    },
  },
  {
    __typename: 'ContentfulYouTubeItem',
    component: YouTube,
    props: (mod: RvG.Contentful.IContentfulYouTubeItem): YouTubeProps => {
      return {
        title: mod.title,
        youTubeId: mod.youTubeId,
        description: mod.description,
        thumbnail: mod.thumbnail,
      }
    },
  },
]

export default PageModuleParser
