import React, { ReactElement } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Grid, GridSize } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Headline from '@objects/headline'
import Container from '@objects/container'
import Teaser from '@objects/teaser'
import Carousel from '@objects/carousel'
import InViewAnimation from '@objects/inViewAnimation'
import Copy from '@components/copy'
import clsx from 'clsx'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ({ isLightTheme }: { isLightTheme: boolean }) =>
      isLightTheme
        ? theme.palette.background.light
        : theme.palette.background.default,
    padding: theme.spacing(8, 0, 10, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(15, 0, 20, 0),
    },
    '& .swiper-slide': {
      display: 'flex',
      alignItems: 'stretch',
      height: 'auto',
    },
  },
  cta: {
    marginTop: theme.spacing(10),
  },
  container: {},
  carouselContainer: {
    '& $headline': {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(4),
      },
    },
    '& > $container': {
      [theme.breakpoints.down('md')]: {
        paddingRight: 0,
        paddingLeft: 0,
        '& .swiper-slide.swiper-slide-active:first-child': {
          marginLeft: theme.spacing(4),
        },
      },
    },
  },
  headline: {},
}))

type TeaserTypes = {
  teaserCopy: RvG.Contentful.BasicRichTextType
  teaserImage?: RvG.Contentful.IAsset
  teaserImageSquare?: RvG.Contentful.IAsset
  teaserTitle: string
  teaserCta: string
  fields?: {
    fullPath?: string
  }
}

export type TeaserGroupProps = RvG.IReactDefaultProps & {
  headline: string
  type: 'Mixed' | '1 Column' | '2 Column' | 'Offset'
  theme: 'Default' | 'Light'
  teasers: Array<TeaserTypes>
  cta?: RvG.Contentful.BasicRichTextType
}

export default function TeaserGroup({
  headline,
  type,
  theme,
  teasers,
  cta,
}: TeaserGroupProps): ReactElement {
  const isLightTheme = theme === 'Light'
  const classes = useStyles({ isLightTheme })
  const muiTheme = useTheme()
  const isLarge = useMediaQuery(muiTheme.breakpoints.up('lg'))
  const intl = useIntl()

  function TeaserCarousel(): ReactElement {
    return (
      <InViewAnimation>
        <Carousel
          slidesPerView={1.5}
          spaceBetween={24}
          breakpoints={{
            [muiTheme.breakpoints.values.md]: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },
          }}
        >
          {teasers?.map((teaser: TeaserTypes, i: number) => (
            <Teaser
              key={`teaser-group-item-${i}`}
              headline={teaser.teaserTitle}
              copy={teaser.teaserCopy}
              image={teaser.teaserImage}
              fullPath={teaser.fields?.fullPath}
              light={isLightTheme}
              ariaRole="menuitem"
            />
          ))}
        </Carousel>
      </InViewAnimation>
    )
  }

  function Mixed() {
    if (!isLarge) {
      return (
        <Grid container justifyContent="space-between" spacing={4} role="group">
          {teasers?.map((teaser: TeaserTypes, i: number) => (
            <Grid key={`teaser-group-item-${i}`} item xs={12} lg={6}>
              <InViewAnimation>
                <Teaser
                  headline={teaser.teaserTitle}
                  copy={teaser.teaserCopy}
                  image={teaser.teaserImage}
                  fullPath={teaser.fields?.fullPath}
                  horizontal={!Boolean(i === 0 || i === 3)}
                  light={isLightTheme}
                  ariaRole="menuitem"
                />
              </InViewAnimation>
            </Grid>
          ))}
        </Grid>
      )
    }

    function getTeaser(idx: number, horizontal: boolean, cols: GridSize = 12) {
      if (!teasers) return
      return (
        <Grid item xs={cols}>
          {!!teasers[idx] && (
            <InViewAnimation>
              <Teaser
                headline={teasers[idx].teaserTitle}
                copy={teasers[idx].teaserCopy}
                image={
                  horizontal && teasers[idx].teaserImageSquare
                    ? teasers[idx].teaserImageSquare
                    : teasers[idx].teaserImage
                }
                fullPath={teasers[idx].fields?.fullPath}
                horizontal={horizontal}
                light={isLightTheme}
                {...(!horizontal ? { fixedContentHeight: 146 } : {})}
                ariaRole="menuitem"
              />
            </InViewAnimation>
          )}
        </Grid>
      )
    }

    if (!teasers) return <div></div>

    switch (teasers.length) {
      case 2:
        return (
          <Grid
            container
            justifyContent="space-between"
            spacing={8}
            role="group"
          >
            {getTeaser(0, false, 6)}
            {getTeaser(1, false, 6)}
          </Grid>
        )
      case 3:
        return (
          <Grid
            container
            justifyContent="space-between"
            spacing={8}
            role="group"
          >
            <Grid item xs={6}>
              <Grid container justifyContent="space-between" spacing={8}>
                {getTeaser(0, false)}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="space-between" spacing={8}>
                {getTeaser(1, true)}
                {getTeaser(2, false)}
              </Grid>
            </Grid>
          </Grid>
        )
      default:
        return (
          <Grid
            container
            justifyContent="space-between"
            spacing={8}
            role="group"
          >
            <Grid item xs={6}>
              <Grid container justifyContent="space-between" spacing={8}>
                {getTeaser(0, false)}
                {getTeaser(1, true)}
                {teasers.length > 4 && getTeaser(4, true)}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container justifyContent="space-between" spacing={8}>
                {getTeaser(2, true)}
                {getTeaser(3, false)}
                {teasers.length > 5 && getTeaser(5, true)}
              </Grid>
            </Grid>
          </Grid>
        )
    }
  }

  function Column({ columns }: { columns: 1 | 2 }): ReactElement {
    if (!isLarge) {
      return <TeaserCarousel />
    }
    return (
      <Grid container justifyContent="space-between" spacing={8} role="group">
        {teasers?.map((teaser: TeaserTypes, i: number) => (
          <Grid
            key={`teaser-group-item-${i}`}
            item
            xs={12}
            lg={columns === 2 ? 6 : 12}
          >
            <InViewAnimation style={{ transitionDelay: `${100 * (i % 2)}ms` }}>
              <Teaser
                headline={teaser.teaserTitle}
                copy={teaser.teaserCopy}
                image={
                  teaser.teaserImageSquare
                    ? teaser.teaserImageSquare
                    : teaser.teaserImage
                }
                fullPath={teaser.fields?.fullPath}
                horizontal
                light={isLightTheme}
                ariaRole="menuitem"
              />
            </InViewAnimation>
          </Grid>
        ))}
      </Grid>
    )
  }

  function Offset(): ReactElement {
    return (
      <Grid container justifyContent="flex-end" spacing={8} role="group">
        {teasers?.map((teaser: TeaserTypes, i: number) => (
          <Grid key={`teaser-group-item-${i}`} item xs={12} lg={8}>
            <InViewAnimation>
              <Teaser
                headline={teaser.teaserTitle}
                copy={teaser.teaserCopy}
                image={teaser.teaserImage}
                fullPath={teaser.fields?.fullPath}
                horizontal={isLarge}
                light={isLightTheme}
                halfAndHalf={isLarge}
                cta={teaser.teaserCta}
                ariaRole="menuitem"
              />
            </InViewAnimation>
          </Grid>
        ))}
      </Grid>
    )
  }

  function TeaserVariantWrapper(): ReactElement {
    switch (type) {
      case 'Mixed':
        return <Mixed />
      case '1 Column':
        return <Column columns={1} />
      case '2 Column':
        return <Column columns={2} />
      case 'Offset':
        return <Offset />
      default:
        return <></>
    }
  }

  return (
    <section
      className={clsx(classes.root, {
        [classes.carouselContainer]: type === '1 Column' || type === '2 Column',
      })}
      role="region"
      aria-label={intl.formatMessage({ id: 'teasergroup.aria.label' })}
    >
      <Container
        className={classes.container}
        type="nomargin"
        aria-label={`${intl.formatMessage({
          id: 'teasergroup.aria.label',
        })} - ${headline}`}
      >
        <InViewAnimation>
          {type === 'Offset' ? (
            <Grid
              container
              justifyContent="flex-end"
              spacing={8}
              aria-label={headline}
            >
              <Grid item xs={12} lg={8}>
                <Headline>{headline}</Headline>
              </Grid>
            </Grid>
          ) : (
            <Headline className={classes.headline}>{headline}</Headline>
          )}
        </InViewAnimation>
        <TeaserVariantWrapper />
        {cta && (
          <Copy
            className={classes.cta}
            richtext={cta}
            type="button"
            theme="light"
          />
        )}
      </Container>
    </section>
  )
}
