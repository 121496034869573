import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import FilterModule from '@components/filterModule'
import Container from '@objects/container'
import _ from 'lodash'
import DownloadShopItem from '@components/downloadShop/item'
import Grid from '@material-ui/core/Grid'
import { Pagination } from '@material-ui/lab'
import InViewAnimation from '@objects/inViewAnimation'
import Button from '@objects/button'

export type DownloadShopProps = RvG.IReactDefaultProps & {
  titleInternal: string
  categories: Array<RvG.Contentful.IContentfulModuleDownloadShopCategory>
}

interface IDownloadShopCategory {
  contentful_id: string
  name: string
  subheadline: string
  downloads: Array<RvG.Contentful.IContentfulModuleDownloadShopItem>
}

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    position: 'relative',
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(0),
    },
  },
  root: {
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(15),
    },
  },
  downloadGrid: {
    marginBottom: theme.spacing(4),
    '& $downloadGridItem': {},
  },
  downloadGridItemWrapper: {},
  downloadGridItem: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '244px',
    },
  },
  backdrop: {
    position: 'absolute',
    height: 'calc(100% - 600px)',
    width: 'calc(100%)',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.light,
    zIndex: -1,
  },
}))

const getDownloadCategories = (
  categories: Array<RvG.Contentful.IContentfulModuleDownloadShopCategory>
) => {
  const categoryList = [{ id: '0', label: 'Alle', contentful_id: '0' }]
  categories.forEach((cat) => {
    if (
      !_.find(categoryList, (item) => {
        return item.id === cat.contentful_id
      })
    ) {
      categoryList.push({
        id: cat.contentful_id,
        label: cat.name,
        contentful_id: cat.contentful_id,
      })
    }
  })

  return categoryList
}

export default function DownloadShop({ ...props }: DownloadShopProps) {
  const theme = useTheme()
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [pageCount, setPageCount] = useState<number>(1)
  const [currentCategory, setCurrentCategory] = useState<string>('0')
  const [filteredDownloadItems, setFilteredDownloadItems] =
    useState<IDownloadShopCategory | null>(null)
  const [paginatedDownloadItems, setPaginatedDownloadItems] = useState<
    [RvG.Contentful.IContentfulModuleDownloadShopItem] | []
  >([])
  const downloadGridRef = useRef(null)
  const perPage = 12
  const [backgroundOffset, setBackgroundOffset] = React.useState<number | null>(
    100
  )

  const { className, categories } = props

  const filterCategories = getDownloadCategories(categories)
  const updateCategory = (categoryId) => {
    setCurrentCategory(() => categoryId)
  }
  const allDownloadItems = []
  categories?.forEach((cat) => {
    cat.downloads?.forEach((catDownloadItem) => {
      if (!allDownloadItems.find((item) => item.id === catDownloadItem.id)) {
        allDownloadItems.push(catDownloadItem)
      }
    })
  })

  useEffect(() => {
    setFilteredDownloadItems(() => {
      if ('0' === currentCategory) {
        const allCategoryItems: IDownloadShopCategory = {
          contentful_id: '0',
          name: 'Alle',
          subheadline: 'Alle',
          downloads: allDownloadItems,
        }
        return allCategoryItems
      } else {
        return _.find(categories, (listItem) => {
          return listItem.contentful_id === currentCategory
        })
      }
    })
  }, [currentCategory])

  const handlePagChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(() => value)
    if (downloadGridRef.current) {
      function offset(el) {
        const rect = el.getBoundingClientRect(),
          scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
      }

      const topVal = offset(downloadGridRef.current).top - 60

      setTimeout(() => {
        window.scrollTo({
          top: topVal,
        })
      }, 200)
    }
  }

  const updatePagination = () => {
    const offset = (page - 1) * perPage

    if (filteredDownloadItems?.downloads) {
      const currentDownloadItemsPage = filteredDownloadItems.downloads.slice(
        offset,
        offset + perPage
      )
      setPaginatedDownloadItems(() => currentDownloadItemsPage)
    } else {
      setPaginatedDownloadItems([])
    }
  }

  useEffect(() => {
    updatePagination()
  }, [page])

  useEffect(() => {
    setPageCount(() => {
      return filteredDownloadItems?.downloads
        ? Math.ceil(filteredDownloadItems?.downloads.length / perPage)
        : 0
    })
    setPage(() => 1)
    updatePagination()
  }, [filteredDownloadItems])

  const handleResize = () => {
    // calculate offset of background to 50% first element image height (+ margintop)
    if (
      downloadGridRef?.current &&
      downloadGridRef?.current?.children?.length
    ) {
      const img = downloadGridRef.current.children?.[0].querySelector(
        '.gatsby-image-wrapper'
      )
      if (img) {
        const offset =
          downloadGridRef.current.offsetTop + img.offsetHeight / 2 + 16
        setBackgroundOffset(offset)
      }
    }
  }

  const throttledHandleResize = _.debounce(handleResize, 200)
  useEffect(() => {
    throttledHandleResize()
    window.addEventListener('resize', throttledHandleResize)
    return () => window.removeEventListener('resize', throttledHandleResize)
  }, [])
  const isMehrAchtung = currentCategory === '1UL3sGttkSQ6wy0KmFuYM6'
  return (
    <section className={classes.outerWrapper}>
      <Container
        className={clsx(classes.root, className)}
        width={'lg'}
        type={'nomargin'}
      >
        <InViewAnimation
          group={[
            <FilterModule
              key={'shopDownload'}
              type={'shopDownload'}
              currentFilter={currentCategory}
              filterOptions={filterCategories}
              onChange={updateCategory}
            />,
            filteredDownloadItems && (
              <h2
                style={{ marginBottom: isMehrAchtung ? 0 : theme.spacing(6) }}
              >
                {filteredDownloadItems.subheadline}
              </h2>
            ),
            isMehrAchtung && (
              <Button
                style={{ marginBottom: theme.spacing(6) }}
                type="underlined"
                to="https://www.mehrachtung.de"
                target="_blank"
              >
                www.mehrachtung.de
              </Button>
            ),
          ]}
        />
        <Grid container className={classes.downloadGrid} ref={downloadGridRef}>
          {paginatedDownloadItems.map((item, i) => {
            return (
              <Grid
                className={classes.downloadGridItemWrapper}
                item
                xs={12}
                md={6}
                lg={3}
                key={`downloadItem-${item.id}`}
              >
                <InViewAnimation
                  style={{
                    transitionDelay: `${100 * (i % 4)}ms`,
                    height: '100%',
                  }}
                >
                  <DownloadShopItem
                    className={classes.downloadGridItem}
                    {...item}
                  />
                </InViewAnimation>
              </Grid>
            )
          })}
        </Grid>
        {pageCount > 1 && (
          <InViewAnimation>
            <Pagination
              boundaryCount={1}
              siblingCount={1}
              count={pageCount}
              page={page}
              onChange={handlePagChange}
            />
          </InViewAnimation>
        )}
      </Container>
      <div
        className={classes.backdrop}
        style={{ height: `calc(100% - ${backgroundOffset}px)` }}
      />
    </section>
  )
}
