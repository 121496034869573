import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { useIntl } from 'react-intl'

import clsx from 'clsx'
import _ from 'lodash'
import * as queryString from 'querystring'
import { useLocation } from '@reach/router'

import useDateFns from '@hooks/useDateFns'

import { RadioGroup } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'

import Copy from '@components/copy'
import FilterModule from '@components/filterModule'
import PressTeaserGridItem from '@components/pressTeaserGrid/pressTeaserGridItem'
import PressTeaserGridMediaItem from '@components/pressTeaserGrid/pressTeaserGridMediaItem'

import useGlobalText from '@hooks/useGlobalText'

import Headline from '@objects/headline'
import Radio from '@objects/formfields/radio'
import Container from '@objects/container'
import InViewAnimation from '@objects/inViewAnimation'
import { updatePagination } from '@objects/pagination'

import RvG from '@/types/rvg'
import { ArticlePage } from '@/types/articlepage'

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    position: 'relative',
    paddingBottom: theme.spacing(1),
  },
  root: {},
  item: {},
  radioItem: {},
  itemRoot: {
    overflow: 'hidden',
    display: 'block',
    margin: theme.spacing(4),
    background: theme.palette.background.default,
    borderRadius: '4px',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4),
    },
  },
  gridWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: theme.spacing(-4),
    marginRight: theme.spacing(-4),
    marginBottom: theme.spacing(10),

    '& $item': {
      flexBasis: '100%',
      maxWidth: '340px',

      [theme.breakpoints.down('sm')]: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      [theme.breakpoints.up('sm')]: {
        flexBasis: 'calc(50% - 32px)',
        minWidth: 'calc(50% - 32px)',
      },
      [theme.breakpoints.up('lg')]: {
        flexBasis: 'calc(25% - 32px)',
        minWidth: 'calc(25% - 32px)',
      },
    },
  },
  backdrop: {
    position: 'absolute',
    height: 'calc(100% - 600px)',
    width: 'calc(100%)',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.light,
    zIndex: -1,
  },
}))

function getYears(
  articles: Array<ArticlePage>,
  getText: CallableFunction
): Array<{ id: string; label: string }> {
  const { formatDateYear } = useDateFns()
  const years = articles.map((article) => {
    const year = formatDateYear(
      article.publishdate || article.updatedAt,
      'dd.MM.yyyy'
    )
    return {
      id: year <= '2018' ? '2018' : year,
      label: year <= '2018' ? '2018' : year,
    }
  })

  if (years?.length < 1) return []

  return years
    .filter((c, i, self) => {
      return self.findIndex((ci) => ci.id === c.id) === i
    })
    .sort((a, b) => {
      if (parseInt(a.id) < parseInt(b.id)) {
        return 1
      } else if (parseInt(a.id) > parseInt(b.id)) {
        return -1
      }
      return 0
    })
}

function getCategories(
  mediaLists: Array<RvG.Contentful.IContentfulMediaList>,
  defaultCat: MediaCategory
): Array<MediaCategory> | null {
  const multiarraycats = mediaLists
    .filter((list) => {
      return !!list.headline
    })
    .map((list) => {
      return {
        id: list.headline,
        label: list.headline,
      }
    })

  if (multiarraycats?.length < 1) return []

  const cats = [defaultCat].concat(
    multiarraycats as unknown as Array<MediaCategory>
  )

  return cats.filter((c, i, self) => {
    return self.findIndex((ci) => ci.id === c.id) === i
  })
}

type MediaCategory = {
  id: string
  label: string
}

export type PressTeaserGridProps = {
  headline?: string
  copy?: RvG.Contentful.BasicRichTextType
  mediaLists?: Array<RvG.Contentful.IContentfulMediaList>
  pressArticles?: Array<ArticlePage>
}

export default function PressTeaserGrid({
  headline,
  copy,
  pressArticles,
  mediaLists,
}: PressTeaserGridProps): React.ReactElement {
  const { formatDateYear } = useDateFns()
  const { getText } = useGlobalText()
  const classes = useStyles()
  const intl = useIntl()
  const location = useLocation()

  const ArticleFilterOptions = pressArticles
    ? getYears(pressArticles, getText)
    : false

  const mediaFilterOptions =
    !!mediaLists?.length &&
    getCategories(mediaLists, {
      id: intl.formatMessage({ id: 'filter.media.all' }),
      label: intl.formatMessage({ id: 'filter.media.all' }),
    })

  const [dateFilter, setDateFilter] = useState<string>(
    ArticleFilterOptions ? ArticleFilterOptions[0].id : ''
  )

  const [currentYearId, setCurrentYearId] = useState<string>(
    ArticleFilterOptions ? ArticleFilterOptions[0].id : ''
  )

  const perPage = 16
  const [page, setPage] = React.useState<number>(1)

  const [pageCount, setPageCount] = useState<number>(1)
  const [typeFilter, setTypeFilter] = useState<'press' | 'media'>('press')

  const [mediaTypeFilter, setMediaTypeFilter] = useState(
    mediaFilterOptions ? mediaFilterOptions[0].id : ''
  )
  const [filteredPressItems, setFilteredPressItems] = useState(
    pressArticles || []
  )
  const [filteredMediaItems, setFilteredMediaItems] =
    useState<
      Array<RvG.Contentful.MediaListImageListContentfulYouTubeItemUnion>
    >()
  const [paginatedMediaItems, setPaginatedMediaItems] =
    useState<
      Array<RvG.Contentful.MediaListImageListContentfulYouTubeItemUnion>
    >()
  const [paginatedPressItems, setPaginatedPressItems] =
    useState<Array<ArticlePage>>()
  const gridWrapper = useRef<HTMLDivElement>(null)
  const [backgroundOffset, setBackgroundOffset] = React.useState<number | null>(
    100
  )

  const updateMediaFilter = (val: string) => {
    setMediaTypeFilter(val)
  }

  useEffect(() => {
    const items = mediaLists
      ?.filter(
        (list) =>
          list.headline === mediaTypeFilter ||
          mediaTypeFilter === intl.formatMessage({ id: 'filter.media.all' })
      )
      ?.map((list) => list.media)
    if (!items?.length) return
    const mediaItems = (
      [] as Array<RvG.Contentful.MediaListImageListContentfulYouTubeItemUnion>
    ).concat(
      ...(items as unknown as Array<RvG.Contentful.MediaListImageListContentfulYouTubeItemUnion>)
    )
    setFilteredMediaItems(mediaItems)
  }, [mediaTypeFilter])

  const handlePagChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(() => value)
    if (gridWrapper.current) {
      function offset(el: HTMLElement) {
        const rect = el.getBoundingClientRect(),
          scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
      }

      const topVal = offset(gridWrapper.current).top - 60

      setTimeout(() => {
        window.scrollTo({
          top: topVal,
        })
      }, 200)
    }
  }

  const filterPressItemsByYear = (filterYearId: string) => {
    if (!pressArticles) return
    if (filterYearId === currentYearId) {
      setFilteredPressItems(pressArticles)
      setCurrentYearId(undefined)
      return
    }
    const filteredPressArticles = pressArticles.filter(
      (article: ArticlePage) => {
        const year = formatDateYear(
          article.publishdate || article.updatedAt,
          'dd.MM.yyyy'
        )
        return (year <= '2018' ? '2018' : year) === filterYearId
      }
    )
    setCurrentYearId(filterYearId)
    if (ArticleFilterOptions) {
      const newYear = ArticleFilterOptions.find(
        (cat) => cat?.id === filterYearId
      )
      if (newYear) {
        setDateFilter(newYear.id)
        navigate(
          `${location.pathname}#${queryString.stringify({
            filterYearId,
          })}`,
          {
            replace: true,
            state: {
              preventScroll: true,
            },
          }
        )
      }
    }
    setFilteredPressItems(filteredPressArticles)
    setPage(() => 1)
  }

  const filterTypeUpdate = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ): void => {
    setTypeFilter(value as 'press' | 'media')
  }

  const handleResize = () => {
    // calculate offset of background to 50% first element image height (+ margintop)
    if (gridWrapper.current && gridWrapper.current?.children?.length) {
      const img: HTMLImageElement | null =
        gridWrapper.current.children[0].querySelector('.gatsby-image-wrapper')
      if (!img) return
      const offset = gridWrapper.current.offsetTop + img.offsetHeight / 2 + 16
      setBackgroundOffset(offset)
    }
  }

  const throttledHandleResize = _.debounce(handleResize, 200)

  useEffect(() => {
    throttledHandleResize()
    window.addEventListener('resize', throttledHandleResize)
    return () => window.removeEventListener('resize', throttledHandleResize)
  }, [])

  useEffect(() => {
    updatePagination({
      page,
      perPage,
      currentYearId,
      filteredMediaItems,
      filteredPressItems,
      typeFilter,
      setPaginatedMediaItems,
      setPaginatedPressItems,
    })
  }, [page])

  useEffect(() => {
    if (typeFilter === 'press') {
      setPageCount(() => {
        return Math.ceil(filteredPressItems.length / perPage)
      })
    }
    if (typeFilter === 'media' && filteredMediaItems) {
      setPageCount(() => {
        return Math.ceil(filteredMediaItems.length / perPage)
      })
    }
    updatePagination({
      page,
      perPage,
      currentYearId,
      filteredMediaItems,
      filteredPressItems,
      typeFilter,
      setPaginatedMediaItems,
      setPaginatedPressItems,
    })
  }, [filteredMediaItems, filteredPressItems, typeFilter])

  useEffect(() => {
    setPage(1)
  }, [filteredMediaItems])

  useEffect(() => {
    if (pressArticles) {
      const filterYearId = queryString.parse(location.hash)?.['#filterYearId']
      if (filterYearId) {
        setCurrentYearId(filterYearId as string)
      }
      const filteredPressArticles = pressArticles.filter((article) => {
        const date: string = article.publishdate || article.createdAt
        const year = date.split('.').pop()

        if (dateFilter === '0' && year) {
          return parseInt(year) < 2018
        }
        if (year) {
          return parseInt(year) === parseInt(filterYearId)
        }
      })
      setFilteredPressItems(filteredPressArticles)
    }
  }, [location.hash])

  return (
    <section className={classes.outerWrapper}>
      <Container className={classes.root}>
        <InViewAnimation
          group={[
            <Headline key={`press-1`} level={2}>
              {headline}
            </Headline>,
            <Copy key={`press-2`} richtext={copy} />,
            <div key="pressTeaserGrid-radioGroup">
              <RadioGroup
                style={{ flexDirection: 'row' }}
                onChange={filterTypeUpdate}
                defaultValue={'press'}
              >
                <Radio
                  className={classes.radioItem}
                  label={'Pressemitteilungen'}
                  name={'pressTypeFilter'}
                  id={'pressTypeFilter'}
                  value={'press'}
                  checked={typeFilter === 'press'}
                />
                <Radio
                  className={classes.radioItem}
                  label={'Mediathek'}
                  name={'pressTypeFilter'}
                  id={'pressTypeFilter'}
                  value={'media'}
                />
              </RadioGroup>
            </div>,
            <>
              {typeFilter === 'press' && ArticleFilterOptions && (
                <FilterModule
                  currentFilter={currentYearId}
                  type={'press'}
                  onChange={filterPressItemsByYear}
                  filterOptions={ArticleFilterOptions}
                />
              )}
              {typeFilter === 'media' && !!mediaFilterOptions && (
                <FilterModule
                  currentFilter={mediaTypeFilter}
                  filterOptions={mediaFilterOptions}
                  type={'media'}
                  onChange={updateMediaFilter}
                />
              )}
            </>,
          ]}
        />

        <div className={classes.gridWrapper} ref={gridWrapper}>
          {typeFilter === 'press' &&
            paginatedPressItems &&
            paginatedPressItems.map((pressItem, i) => {
              return (
                <InViewAnimation
                  style={{ transitionDelay: `${100 * (i % 4)}ms` }}
                  className={clsx(classes.item, classes.itemRoot)}
                  key={`press-item-${pressItem.id}`}
                >
                  <PressTeaserGridItem item={pressItem} />
                </InViewAnimation>
              )
            })}

          {typeFilter === 'media' &&
            paginatedMediaItems &&
            paginatedMediaItems.map((mediaItem, i) => {
              return (
                <InViewAnimation
                  style={{ transitionDelay: `${100 * (i % 4)}ms` }}
                  className={clsx(classes.item, classes.itemRoot)}
                  key={`press-item-${mediaItem.id}`}
                >
                  <PressTeaserGridMediaItem item={mediaItem} />
                </InViewAnimation>
              )
            })}
        </div>
        {pageCount > 1 && (
          <InViewAnimation>
            <Pagination
              boundaryCount={1}
              siblingCount={1}
              count={pageCount}
              page={page}
              onChange={handlePagChange}
            />
          </InViewAnimation>
        )}
      </Container>
      <div
        className={classes.backdrop}
        style={{ height: `calc(100% - ${backgroundOffset}px)` }}
      />
    </section>
  )
}
