import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { Pagination } from '@material-ui/lab'
import { updatePagination } from '@objects/pagination'

import RvG, { Contentful } from '@/types/rvg'
import { ArticlePage } from '@/types/articlepage'
import useGlobalText from '@hooks/useGlobalText'
import useDateFns from '@hooks/useDateFns'

import TeaserGridFilter from './teaserGridFilter'
import Teaser from '@objects/teaser'
import InViewAnimation from '@objects/inViewAnimation'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLocation } from '@reach/router'
import * as queryString from 'querystring'
import _ from 'lodash'

import Container from '@objects/container'
import Copy from '@components/copy'
import Headline from '@objects/headline'

const useStyles = makeStyles((theme) => ({
  outerWrapper: {
    position: 'relative',
  },
  root: {
    position: 'relative',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(15),
    },
  },
  gridWrapper: {
    marginBottom: theme.spacing(8),
  },
  articleTeaser: {
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  headline: {},
  infotext: {
    ...theme.typography.infotext,
  },

  gridHeadline: {
    marginTop: `${theme.spacing(12)}px!important`,
  },
  sortingWrapper: {
    display: 'flex',
    // flexDirection:'row'
  },
  sortingButtonsGroup: {
    flexDirection: 'row!important' as 'row',
    '& label': {
      marginRight: theme.spacing(6),
    },
  },
  sortingLabel: {
    fontWeight: 700,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    '& span': {
      marginRight: theme.spacing(6),
    },
  },
  backdrop: {
    position: 'absolute',
    height: 'calc(100% - 600px)',
    width: 'calc(100%)',
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.background.light,
    zIndex: -1,
  },
}))

const defaultCategory: Contentful.Category = {
  name: 'Alle Themen',
  id: '0',
  headline: 'Alle Themen',
  __typename: '',
  contentful_id: '',
}

function getCategories(
  articles: Array<ArticlePage>
): Array<RvG.Contentful.ICategory> | null {
  const multiarraycats = articles
    .filter((article) => {
      return !!article.category
    })
    .map((article) => {
      return article.category
    })

  if (multiarraycats?.length < 1) return []

  const cats: Array<RvG.Contentful.ICategory> = [defaultCategory].concat(
    ...(multiarraycats as unknown as Array<RvG.Contentful.ICategory>)
  )

  return cats.filter((c, i, self) => {
    return self.findIndex((ci) => ci.id === c.id) === i
  })
}

function getYears(
  articles: Array<ArticlePage>,
  getText: CallableFunction
): Array<{ id: string; name: string }> {
  const { formatDateYear } = useDateFns()
  const years = articles.map((article) => {
    const year = formatDateYear(
      article.publishdate || article.updatedAt,
      'dd.MM.yyyy'
    )
    return {
      id: year <= '2018' ? '2018' : year,
      name: year <= '2018' ? getText('UpTo2018') : year,
    }
  })

  if (years?.length < 1) return []

  return years.filter((c, i, self) => {
    return self.findIndex((ci) => ci.id === c.id) === i
  })
}

export type ArticleTeaserGridProps = RvG.IReactDefaultProps & {
  headline?: string
  titleInternal?: string
  copy?: RvG.Contentful.BasicRichTextType
  showYearFilter?: boolean
  showCategoryFilter?: 'Hide' | 'Radio' | 'Buttons'
  articles?: Array<ArticlePage>
  sortByCustomUpdatedDate: boolean
}

export default function ArticleTeaserGrid({
  ...props
}: ArticleTeaserGridProps): ReactElement {
  const { formatDateYear } = useDateFns()
  const { getText } = useGlobalText()
  const location = useLocation()
  const gridWrapper = useRef<HTMLDivElement>(null)
  const muiTheme = useTheme()
  const isLarge = useMediaQuery(muiTheme.breakpoints.up('lg'))
  const classes = useStyles()
  const { headline, copy, articles, showCategoryFilter, showYearFilter } = props

  let pageArticles: ArticlePage[] | undefined = []
  pageArticles = articles ?? []

  const perPage = 16
  const [page, setPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number | null>()

  const [backgroundOffset, setBackgroundOffset] = React.useState<number | null>(
    100
  )
  const [currentCategory, setCurrentCategory] =
    useState<Contentful.Category>(defaultCategory)
  const [currentCategoryId, setCurrentCategoryId] = useState<string | null>('0')
  const [currentYearId, setCurrentYearId] = useState<string>()

  const [filteredArticles, setFilteredArticles] = useState<ArticlePage[]>([])
  const [paginatedArticles, setPaginatedArticles] = useState<ArticlePage[]>([])

  const categories =
    showCategoryFilter === 'Buttons' && getCategories(pageArticles)

  const years = !!showYearFilter && getYears(pageArticles, getText)

  const [pageCount, setPageCount] = useState<number>(1)

  const handlePagChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(() => value)

    if (gridWrapper.current) {
      function offset(el: HTMLElement | null) {
        if (!el) return { top: 60 }
        const rect = el.getBoundingClientRect(),
          scrollLeft =
            window.pageXOffset || document.documentElement.scrollLeft,
          scrollTop = window.pageYOffset || document.documentElement.scrollTop
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
      }

      const topVal = offset(gridWrapper.current).top - 60

      setTimeout(() => {
        window.scrollTo({
          top: topVal,
        })
      }, 200)
    }
  }

  const handleResize = () => {
    // calculate offset of background to 50% first element image height (+ margintop)
    if (gridWrapper.current && gridWrapper.current?.children.length) {
      const img: HTMLImageElement | null =
        gridWrapper.current?.children[0].querySelector('.gatsby-image-wrapper')
      if (!img) return
      const offset = gridWrapper.current?.offsetTop + img.offsetHeight / 2 + 16
      setBackgroundOffset(offset)
    }
  }

  const throttledHandleResize = _.debounce(handleResize, 200)

  useEffect(() => {
    throttledHandleResize()
    window.addEventListener('resize', throttledHandleResize)
    return () => window.removeEventListener('resize', throttledHandleResize)
  }, [])

  const filterArticlesByCategory = (filterCategoryId: string) => {
    if (!pageArticles) return
    if (filterCategoryId === currentCategoryId) {
      setFilteredArticles(pageArticles)

      setCurrentCategoryId('0')
      return
    }
    let filteredArticles: ArticlePage[] = []
    if ('0' === filterCategoryId) {
      filteredArticles = pageArticles
      setCurrentCategoryId('0')

      setCurrentCategory(defaultCategory)
    } else {
      filteredArticles = pageArticles.filter((article: ArticlePage) => {
        return article?.category?.find((cat) => cat.id === filterCategoryId)
      })
      setCurrentCategoryId(filterCategoryId)

      if (categories) {
        const newCategory = categories.find(
          (cat) => cat?.id === filterCategoryId
        )
        if (newCategory) {
          navigate(
            `${location.pathname}#${queryString.stringify({
              filterCategoryId,
              currentPage,
            })}`,
            {
              replace: true,
              state: {
                preventScroll: true,
              },
            }
          )
          setCurrentCategory(newCategory)
        }
      }
    }
    setFilteredArticles(filteredArticles)

    setPage(1)
  }

  const filterArticlesByYear = (filterYearId: string) => {
    if (!pageArticles) return
    if (filterYearId === currentYearId) {
      setFilteredArticles(pageArticles)
      setCurrentYearId(undefined)
      return
    }
    let filteredArticles: ArticlePage[] = []
    filteredArticles = pageArticles.filter((article: ArticlePage) => {
      const year = formatDateYear(
        article.publishdate || article.updatedAt,
        'dd.MM.yyyy'
      )
      return (year <= '2018' ? '2018' : year) === filterYearId
    })
    setCurrentYearId(filterYearId)
    if (years) {
      const newYear = years.find((cat) => cat?.id === filterYearId)
      if (newYear) {
        navigate(
          `${location.pathname}#${queryString.stringify({
            filterYearId,
          })}`,
          {
            replace: true,
            state: {
              preventScroll: true,
            },
          }
        )
      }
    }
    setFilteredArticles(filteredArticles)
    setPage(() => 1)
  }

  useEffect(() => {
    if (!pageArticles) return
    setFilteredArticles(pageArticles)
    const newCurrentPage =
      queryString.parse(location.hash)?.['#filterYearId'] === undefined
        ? queryString.parse(location.hash)?.['#filterPage']
        : queryString.parse(location.hash)?.['filterPage']
    const newCurrentPageNumber = parseInt(newCurrentPage as string)
    setCurrentPage(newCurrentPageNumber)
  }, [])

  useEffect(() => {
    updatePagination({
      page,
      currentCategory,
      perPage,
      currentYearId,
      filteredArticles,
      setPaginatedArticles,
    })
  }, [page])

  useEffect(() => {
    setPageCount(() => {
      return Math.ceil(filteredArticles.length / perPage)
    })
    updatePagination({
      page,
      currentCategory,
      perPage,
      currentYearId,
      filteredArticles,
      setPaginatedArticles,
    })
  }, [filteredArticles])

  useEffect(() => {
    if (!categories) return

    const filterCategoryId = queryString.parse(location.hash)?.[
      '#filterCategoryId'
    ]

    const valid = categories?.find((cat) => cat?.id === filterCategoryId)
    let filteredArticles: ArticlePage[] = []
    if (filterCategoryId && valid) {
      setCurrentCategoryId(filterCategoryId as string)
    }
    if (categories) {
      const newCategory = categories.find((cat) => cat?.id === filterCategoryId)

      if (newCategory) {
        filteredArticles = pageArticles.filter((article: ArticlePage) => {
          return article?.category?.find((cat) => cat.id === filterCategoryId)
        })
        setCurrentCategory(newCategory)
        setFilteredArticles(filteredArticles)
      }
      const filterPage =
        queryString.parse(location.hash)?.['#filterCategoryId'] === undefined
          ? queryString.parse(location.hash)?.['#filterPage']
          : queryString.parse(location.hash)?.['filterPage']

      const filterPageNumber = parseInt(filterPage as string)
      setPage(() => filterPageNumber)
    }
  }, [location.hash])

  useEffect(() => {
    if (!years) return
    const filterYearId = queryString.parse(location.hash)?.['#filterYearId']

    const valid = years?.find((cat) => cat?.id === filterYearId)
    let filteredArticles: ArticlePage[] = []
    if (filterYearId && valid) {
      setCurrentCategoryId(filterYearId as string)
    }
    if (years) {
      const newYear = years.find((cat) => cat?.id === filterYearId)

      if (newYear) {
        filteredArticles = pageArticles.filter((article: ArticlePage) => {
          const year = formatDateYear(
            article.publishdate || article.updatedAt,
            'dd.MM.yyyy'
          )
          return (year <= '2018' ? '2018' : year) === filterYearId
        })
        setCurrentYearId(newYear.id)
        setFilteredArticles(filteredArticles)
      }

      const filterPage =
        queryString.parse(location.hash)?.['#filterYearId'] === undefined
          ? queryString.parse(location.hash)?.['#filterPage']
          : queryString.parse(location.hash)?.['filterPage']

      const filterPageNumber = parseInt(filterPage as string)
      setPage(() => filterPageNumber)
    }
  }, [location.hash])

  if (isNaN(page)) {
    setPage(() => 1)
  }

  return (
    <>
      <section className={classes.outerWrapper}>
        <Container className={classes.root} type={'nomargin'}>
          <InViewAnimation>
            <Headline className={classes.headline} level={2}>
              {headline}
            </Headline>
          </InViewAnimation>
          <InViewAnimation>
            {copy && (
              <div className={classes.infotext}>
                <Copy className={classes.infotext} richtext={copy} />
              </div>
            )}
          </InViewAnimation>
          <InViewAnimation>
            {!!categories && categories.length > 0 && (
              <TeaserGridFilter
                filterOptions={categories}
                onChange={filterArticlesByCategory}
                currentId={currentCategoryId}
              />
            )}
            {!!years && years.length > 0 && (
              <TeaserGridFilter
                filterOptions={years}
                onChange={filterArticlesByYear}
                currentId={currentYearId}
              />
            )}
            {currentCategory?.headline && (
              <Headline className={classes.gridHeadline} level={2}>
                {currentCategory?.headline}
              </Headline>
            )}
          </InViewAnimation>
          <Grid
            ref={gridWrapper}
            className={classes.gridWrapper}
            container
            spacing={8}
          >
            {paginatedArticles.map((article, i) => {
              return (
                <Grid key={article.id} item xs={12} md={6} lg={3}>
                  <InViewAnimation
                    style={{
                      transitionDelay: `${100 * (i % 4)}ms`,
                      height: '100%',
                    }}
                  >
                    <Teaser
                      className={classes.articleTeaser}
                      headline={article.teaserTitle}
                      copy={article.teaserCopy}
                      image={article.teaserImage}
                      fullPath={article.fields.fullPath}
                      readingTime={article.readingTime}
                      showReadingTime={true}
                    />
                  </InViewAnimation>
                </Grid>
              )
            })}
          </Grid>
          <InViewAnimation>
            {pageCount > 1 && (
              <Pagination
                boundaryCount={isLarge ? 1 : 1}
                siblingCount={isLarge ? 1 : 1}
                count={pageCount}
                page={page}
                onChange={handlePagChange}
              />
            )}
          </InViewAnimation>
        </Container>
        <div
          className={classes.backdrop}
          style={{ height: `calc(100% - ${backgroundOffset}px)` }}
        />
      </section>
    </>
  )
}
