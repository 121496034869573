import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useToggleComp from '@hooks/useToggleComp'
import Img, { FluidObject } from 'gatsby-image'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import clsx from 'clsx'
import Icon from '@objects/icon'

export type DownloadShopItemProps = RvG.IReactDefaultProps & {
  id: string
  headline: string
  copy: RvG.Contentful.BasicRichTextType
  thumbnails: Array<RvG.Contentful.IAsset>
  file: RvG.Contentful.IAsset
  amount: Array<RvG.Contentful.IContentfulModuleDownloadShopItemAmount>
  sku?: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    background: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',

    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100% - 24px)',
    },
  },
  imgWrapper: {
    backgroundColor: theme.palette.background.greyDark,
    transition: 'padding 0.2s linear',
    cursor: 'pointer',

    [theme.breakpoints.up('md')]: {
      maxHeight: '244px',
      height: '244px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      '& img': {
        objectFit: 'contain !important',
        maxHeight: '244px',
      },
    },
    [theme.breakpoints.up('lg')]: {
      // padding: theme.spacing(8, 0),
    },

    '$showActions &': {
      paddingBottom: 0,
    },
  },
  itemContentWrapper: {
    padding: theme.spacing(4),
    // overflow: 'hidden',
    position: 'relative',
    background: 'white',
    top: 0,
    transition: 'all 0.2s linear',
    [theme.breakpoints.up('lg')]: {
      '$showActions &': {
        transform: `translateY(-36px)`, // theme.spacing(-15.5),
        marginBottom: '-36px',
      },
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  showActions: {},
  itemHeadline: {},
  itemActionsBar: {
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    '& svg': {
      cursor: 'pointer',
      color: 'red',
    },
    transition: 'all 0.2s linear',
    maxHeight: 0,
    overflow: 'hidden',
    height: 'auto',
    '$showActions &': {
      maxHeight: '36px', // theme.spacing(16),
      [theme.breakpoints.up('lg')]: {
        // marginBottom: theme.spacing(-16),
      },
    },
  },
  itemActionsBarInner: {
    display: 'flex',
    marginTop: 'auto',
    flexDirection: 'row',
  },
  downloadAction: {
    marginRight: theme.spacing(2),
    '& svg': {
      transform: 'scale(1.08) translateY(-2px)',
    },
  },
  basketAction: {
    '& svg': {
      width: '36px',
      height: '36px',
      fontSize: '36px',
    },
  },
}))

export default function DownloadShopItem({
  ...props
}: DownloadShopItemProps): React.ReactElement {
  const classes = useStyles()
  const { toggleOverlay } = useToggleComp()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const [showActions, setShowActions] = useState<boolean>(false)

  const { className, headline, thumbnails, file, amount } = props

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.showActions]: !isDesktop || showActions,
      })}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      <div
        className={classes.imgWrapper}
        onClick={() => {
          toggleOverlay(true, {
            type: 'downloadShop',
            paperProps: { className: 'dark' },
            downloadProps: {
              ...props,
            },
            closeButton: 'hide',
          })
        }}
      >
        {isDesktop && (
          <Img fluid={{ ...thumbnails?.[0]?.fluid } as FluidObject} alt={''} />
        )}
        {!isDesktop && (
          <Img fluid={{ ...thumbnails?.[0]?.fluid } as FluidObject} alt={''} />
        )}
      </div>
      <div className={classes.itemContentWrapper}>
        <div className={classes.itemHeadline}> {headline}</div>
        <div className={clsx(classes.itemActionsBar, {})}>
          <div className={clsx(classes.itemActionsBarInner, {})}>
            {file?.file && (
              <div className={classes.downloadAction}>
                <a
                  href={file.file.url}
                  target={'_blank'}
                  download
                  rel="noreferrer"
                >
                  <Icon viewBox={'0 0 15 15'} name={'DownloadColored'} />
                </a>
              </div>
            )}
            {amount?.length > 0 && (
              <div
                className={classes.basketAction}
                onClick={() => {
                  toggleOverlay(true, {
                    type: 'downloadShop',
                    paperProps: { className: 'dark' },
                    downloadProps: {
                      ...props,
                    },
                    closeButton: 'hide',
                  })
                }}
              >
                <Icon viewBox={'0 0 36 36'} name={'BasketColored'} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
