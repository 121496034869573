import React from 'react'
import Copy from '@components/copy'
import Container from '@objects/container'

export type TextModuleProps = {
  copy?: RvG.Contentful.BasicRichTextType
}

export default function TextModule({
  copy,
}: TextModuleProps): React.ReactElement {
  return (
    <Container type="article">{!!copy && <Copy richtext={copy} />}</Container>
  )
}
